/*
 * Apply the attributes to the item itself and any of his children that was not directly changed.
 */
export function replicateUntilChanged(drawingItemId, attributes, itemsState) {
  const children = itemsState.filter(item => (item.parentKey == drawingItemId && !item.hasChanged) || (item.id == drawingItemId))
  return children.reduce(
    (newState, item) => newState.update(
      item.id,
      currentItem => Object.assign({}, currentItem, attributes)
    ),
    itemsState
  ).update(drawingItemId, currentItem => Object.assign({}, currentItem, {hasChanged: true}))
}


export function replicateAlways(drawingItemId, attributes, itemsState) {
  const parentKey = itemsState.get(drawingItemId).parentKey || drawingItemId

  const childrenToUpdate = itemsState
    .filter(item => item.parentKey == parentKey || item.id == parentKey)

  return childrenToUpdate.reduce(
    (newState, item) => newState.update(
      item.id,
      currentItem => Object.assign({}, currentItem, attributes)
    ),
    itemsState
  )
}
