import {combineReducers} from 'redux'

import publication from './publication'
import contents from './contents'
import authentication from './authentication'

// Use some layouter reducers needed to render the article detail screen
import main from '../../layouter/reducers/main'
import fonts from '../../layouter/reducers/fonts'
import items from '../../layouter/reducers/items'
import properties from '../../layouter/reducers/properties'
import placement from '../../layouter/reducers/placement'
import options from '../../layouter/reducers/options'
import pages from '../../layouter/reducers/pages'
import loading from '../../common/reducers/loading'

const reducer = combineReducers({
  publication,
  contents,
  authentication,

  // Article detail related
  main,
  fonts,
  items,
  properties,
  placement,
  options,
  pages,
  loading,
})

export default reducer
