import React from 'react'
import { withTranslation } from 'react-i18next'
import connector from 'player/connector'
import Modal from '../../../common/components/Modal'

@withTranslation()
@connector(['authentication', 'actions'])
export default class Logout extends React.Component {
  render() {
    const {t} = this.props

    return (
      <div className='modal-container login-modal-container'>
        <Modal closeModal={this.cancel}>
          <h3 className='form-title'>{t('Please confirm logout')}</h3>
          <div className='btn-group btn-group-vertical'>
            <button
              type='button'
              className='btn submit'
              onClick={this.handleLogoutClick}
              disabled={this.props.authentication.isFetching}
            >
              <span>{t('Log out')}</span>
            </button>
          </div>
        </Modal>
      </div>
    )
  }

  cancel = () => this.props.actions.closeAuthenticationModal()

  handleLogoutClick = () => this.props.actions.logout()
    .then(this.props.actions.closeAuthenticationModal)
}
