import types from '../actionTypes'
import {OrderedMap} from 'immutable'

const defaultState = {
  pages: OrderedMap(),
  active: null,
  previous: null,
  next: null,
}

export default function pages(state=defaultState, action) {
  switch (action.type) {
    case types.ARTICLE_PAGES_FETCH_DONE:
      return {
        ...state,
        pages: state.pages.clear().merge(action.pages),
        active: action.pages.first().id,
        next: (action.pages.size > 1) ? action.pages.toList().get(1).id : null,
        prev: null,
      }

    case types.ARTICLE_PAGE_SELECTED: {
      const pagesKeys = state.pages.keySeq()
      const index = pagesKeys.indexOf(action.payload)
      return {
        ...state,
        active: action.payload,
        next: (index < (pagesKeys.size - 1)) ? pagesKeys.get(index + 1) : null,
        prev: (index > 0) ? pagesKeys.get(index - 1) : null,
      }
    }

    case types.CREATE_ARTICLE_PAGE:
      return {
        ...state,
        pages: state.pages.set(action.payload.page.id, action.payload.page),
      }

    case types.TOGGLE_PAGE_REMOVED:
      return {
        ...state,
        pages: state.pages.update(action.payload.pageId, page => ({ ...page, isRemoved: !page.isRemoved })),
      }

    case types.TOGGLE_PAGE_LOCKED:
      return {
        ...state,
        pages: state.pages.update(action.payload.pageId, page => ({ ...page, isLocked: !page.isLocked })),
      }

    case types.TOGGLE_PAGE_HIDDEN:
      return {
        ...state,
        pages: state.pages.update(action.payload.pageId, page => ({ ...page, isHidden: !page.isHidden })),
      }

    case types.SET_ARTICLE_PAGE_TITLE:
      return {
        ...state,
        pages: state.pages.update(action.payload.pageId, page => ({ ...page, title: action.payload.title })),
      }

    case types.SET_PAGES_ORDER:
      return {
        ...state,
        pages: state.pages.mergeWith(
          (oldVal, newVal) => ({...oldVal, index: newVal}),
          action.payload,
        ),
      }

    case types.PAGE_BACKGROUND_COLOR_CHANGED:
      return {
        ...state,
        pages: state.pages.update(action.payload.pageId, page => ({ ...page, backgroundColor: action.payload.color })),
      }

    default:
      return state
  }
}
