export function getYoutubeVideoId(url) {
  let match = url.match(/https?:\/\/www\.youtube\.com\/watch\?v=([^&]+)/)
  if(match) {
    return match[1]
  }

  match = url.match(/https?:\/\/youtu\.be\/(.*)\/?/)
  if(match) {
    return match[1]
  }

  return null
}
