import stringify from 'json-stable-stringify'
import {
  EditorState,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { colorToCss } from './common'
import { removeSelectionStyle } from './text'

export class EditorStyler {
  constructor(editorState, props, nextProps) {
    this.editorState = editorState
    this.props = props
    this.nextProps = nextProps
  }

  apply() {
    if(this.isHold()) {
      return null
    } else if(this.hasChangedStyles()) {
      return this.applyStyles()
    } else if(this.hasChangedEntities()) {
      return this.applyEntities()
    } else {
      return null
    }
  }

  isHold() {
    return this.props.textStyleHolder != this.nextProps.textStyleHolder
  }

  hasChangedStyles() {
    const props = [
      'appliedTextBold', 'appliedTextItalic',
      'appliedTextUnderline', 'appliedTextStrikeThrough',
      'appliedTextBGColor', 'appliedTextFGColor',
    ]
    for(let i=0; i<props.length; i++) {
      if(JSON.stringify(this.props[props[i]]) !== JSON.stringify(this.nextProps[props[i]])) {
        return true
      }
    }
    return false
  }

  applyStyles() {
    const [stylesToApply, colorsToBeCleared] = this.getApplicableStyles()

    let [editorState, anchorOffset] = this.getSelectedEditorState()

    if(colorsToBeCleared.length) {
      editorState = this.getColorlessEditorState(editorState, colorsToBeCleared)
    }

    if (stylesToApply.length > 0) {
      editorState = stylesToApply.reduce(
        (editor, style) => RichUtils.toggleInlineStyle(editor, style),
        editorState
      )
    }

    if(anchorOffset !== null) {
      editorState = this.reselect(editorState, anchorOffset)
    }

    return editorState
  }

  getApplicableStyles() {
    const stylesToApply = []
    const editorColorsToBeCleared = []

    const fgColor = this.getApplicableFGColor()
    if (fgColor) {
      stylesToApply.push('fg:' + fgColor)
      editorColorsToBeCleared.push('fg')
    }

    const bgColor = this.getApplicableBGColor()
    if (bgColor) {
      stylesToApply.push('bg:' + bgColor)
      editorColorsToBeCleared.push('bg')
    }

    if (this.props.appliedTextBold !== this.nextProps.appliedTextBold) {
      stylesToApply.push('BOLD')
    }

    if (this.props.appliedTextItalic !== this.nextProps.appliedTextItalic) {
      stylesToApply.push('ITALIC')
    }

    if (this.props.appliedTextUnderline !== this.nextProps.appliedTextUnderline) {
      stylesToApply.push('UNDERLINE')
    }

    if (this.props.appliedTextStrikeThrough !== this.nextProps.appliedTextStrikeThrough) {
      stylesToApply.push('STRIKE_THROUGH')
    }

    return [stylesToApply, editorColorsToBeCleared]
  }

  getApplicableColor(before, after) {
    const hasChanged = stringify(before) != stringify(after)
    if (after && hasChanged) {
      return colorToCss(after)
    }
  }

  getApplicableFGColor() {
    return this.getApplicableColor(
      this.props.appliedTextFGColor,
      this.nextProps.appliedTextFGColor
    )
  }

  getApplicableBGColor() {
    return this.getApplicableColor(
      this.props.appliedTextBGColor,
      this.nextProps.appliedTextBGColor
    )
  }

  /**
   * Returns the state itself for any selection or make a whole selection.
   */
  getSelectedEditorState() {
    const selection = this.editorState.getSelection()
    if (selection.anchorOffset == selection.focusOffset) {
      return [this.selectEntireBlock(), selection.anchorOffset]
    } else {
      return [this.editorState, null]
    }
  }

  /**
   * Return a new Editor State with the entire block selected
   */
  selectEntireBlock() {
    const currentKey = this.editorState.getSelection().getAnchorKey()
    const currentBlock = this.editorState.getCurrentContent().getBlockForKey(currentKey)
    const selectionState = SelectionState.createEmpty()
    const entireBlockSelectionState = selectionState.merge({
      anchorKey: currentKey,
      anchorOffset: 0,
      focusKey: currentKey,
      focusOffset: currentBlock.getText().length
    })
    return EditorState.forceSelection(this.editorState, entireBlockSelectionState)
  }

  reselect(editorState, anchorOffset) {
    const currentKey = this.editorState.getSelection().getAnchorKey()
    const selectionState = SelectionState.createEmpty()
    const reselectedSelectionState = selectionState.merge({
      anchorKey: currentKey,
      anchorOffset: anchorOffset,
      focusKey: currentKey,
      focusOffset: anchorOffset,
    })
    return EditorState.forceSelection(editorState, reselectedSelectionState)
  }

  /**
   * Return editor state without color styles for current selection
   */
  getColorlessEditorState(editorState, colorsToBeCleared) {
    return colorsToBeCleared.reduce((state, color) => {
      return removeSelectionStyle(
        state,
        value => value.startsWith(`${color}:rgba`)
      )
    }, editorState)
  }

  hasChangedEntities() {
    return this.props.appliedTextLink !== this.nextProps.appliedTextLink
  }

  applyEntities() {
    let editorState = this.editorState
    let entityKey
    const contentState = editorState.getCurrentContent()
    if(this.nextProps.appliedTextLink) {
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        {url: this.nextProps.appliedTextLink}
      )
      editorState = EditorState.set(editorState, {currentContent: contentStateWithEntity})
      entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    } else {
      entityKey = null
    }
    editorState = RichUtils.toggleLink(
      editorState,
      editorState.getSelection(),
      entityKey
    )
    return editorState
  }
}
