import 'isomorphic-fetch'
import cookies from 'react-cookie'

import * as types from './actionTypes'
import settings, {urls} from '../settings'

export const fetchPaymentCompanies = () => dispatch => {
  dispatch({type: types.START_PAYMENT_COMPANIES_FETCH})
  return fetch(settings.fetchAllPaymentCompanies())
    .then(response => response.json())
    .then(json => dispatch({type: types.PAYMENT_COMPANIES_FETCHED, payload: json}))
    .catch(err => {
      dispatch({type: types.LOADING_ERROR})
      throw err
    })
}


export const registeredEmailValidation = (email) => dispatch => {
  dispatch({type: types.START_EMAIL_VALIDATION})
  return fetch(settings.emailValidationURL(),
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({ email })
    })
    .then(response => response.json())
    .then(json => dispatch({type: types.EMAIL_VALIDATED, payload: json}))
    .catch(() => dispatch({type: types.EMAIL_VALIDATION_ERROR, payload: 'Could not validate'}))
}

export const validatePublicationSlug = (slug, publicationId) => dispatch => {
  dispatch({type: types.START_PUBLICATION_SLUG_VALIDATION})
  const csrftoken = cookies.load('csrftoken')
  return fetch(settings.publicationSlugValidationURL(publicationId),
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      }),
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({slug})
    })
    .then(response => response.json())
    .then(json => dispatch({type: types.PUBLICATION_SLUG_VALIDATED, payload: json}))
    .catch(() => dispatch({type: types.PUBLICATION_SLUG_VALIDATION_ERROR, payload: 'Could not validate'}))
}

export const validateArticleSlug = (slug, publicationId, articleId) => dispatch => {
  dispatch({type: types.START_ARTICLE_SLUG_VALIDATION})
  const csrftoken = cookies.load('csrftoken')
  return fetch(settings.articleSlugValidationURL(publicationId, articleId),
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      }),
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({slug})
    })
    .then(response => response.json())
    .then(json => dispatch({type: types.ARTICLE_SLUG_VALIDATED, payload: json}))
    .catch(() => dispatch({type: types.ARTICLE_SLUG_VALIDATION_ERROR, payload: 'Could not validate'}))
}

export const validateAmpstorySlug = (slug, ampstoryId) => dispatch => {
  dispatch({type: types.AMPSTORY_SLUG_VALIDATION_STARTED})
  const csrftoken = cookies.load('csrftoken')
  return fetch(urls.ampstories.layouter.slugValidationUrl(),
    {
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      }),
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        slug,
        ampstoryId,
      })
    })
    .then(response => response.json())
    .then(json => dispatch({type: types.AMPSTORY_SLUG_VALIDATION_DONE, payload: json}))
    .catch(err => dispatch({type: types.AMPSTORY_SLUG_VALIDATION_FAILED, payload: {err}}))
}
