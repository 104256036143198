import types from '../actionTypes'
import {List} from 'immutable'


export const defaultState = {
  items: List(),
  nextPage: '',
  contentListVisible: false,
}

export default (state=defaultState, action) => {
  switch(action.type) {
    case types.LOAD_CONTENTS_DONE:
      return {
        ...state,
        items: state.items.concat(action.payload.results),
        nextPage: action.payload.next
      }

    case types.CONTENT_LIST_RESET:
      return {
        ...state,
        items: state.items.clear(),
        nextPage: '',
      }

    case types.SHOW_CONTENT_LIST:
      return Object.assign({}, state, {contentListVisible: true})

    case types.HIDE_CONTENT_LIST:
      return Object.assign({}, state, {contentListVisible: false})

    default:
      return state
  }
}
