import React from 'react'
import connector from "player/connector"
import AmpstoryItem from './AmpstoryItem'
import ArticleItem from './ArticleItem'

@connector(['contents'])
export default class List extends React.Component {
  render() {
    return (
      <div className="list">
        {this.props.contents.items.toArray().map(content => {
          if (content.type === 'ampstory') {
            return <AmpstoryItem key={`ampstory-${content.pk}`} content={content} />
          } else {
            return <ArticleItem key={`article-${content.pk}`} content={content} />
          }
        })}
      </div>
    )
  }
}
