import {
  SET_SHAPE_ITEM_BG_COLOR, SET_SHAPE_DEFAULT_COLOR,
  SET_SHAPE_ITEM_BORDER_COLOR, SET_SHAPE_BORDER_SIZE,
  SET_SHAPE_DEFAULT_BORDER_SIZE, SET_SHAPE_DEFAULT_BORDER_COLOR
} from '../../actionTypes'

export const setShapeItemBGColor = (drawingItemId, color) => (dispatch) => {
  dispatch({type: SET_SHAPE_ITEM_BG_COLOR, drawingItemId, color})
  dispatch({type: SET_SHAPE_DEFAULT_COLOR, color})
}

export const setShapeItemBorderColor = (drawingItemId, color) => (dispatch) => {
  dispatch({type: SET_SHAPE_ITEM_BORDER_COLOR, drawingItemId, color})
  dispatch({type: SET_SHAPE_DEFAULT_BORDER_COLOR, color})
}

export const setShapeBorderSize = (drawingItemId, size) => (dispatch) => {
  dispatch({type: SET_SHAPE_BORDER_SIZE, drawingItemId, size})
  dispatch({type: SET_SHAPE_DEFAULT_BORDER_SIZE, size})
}
