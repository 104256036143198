import React from 'react'

const LightBox = ({ static: staticFlag }) => {
  return (
    <div className={`lightbox ${staticFlag ? 'static' : ''}`}>
      <div className="loading"></div>
    </div>
  )
}

export default LightBox
