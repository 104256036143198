import React from 'react'
import { withRouter } from 'react-router'
import connector from "player/connector"
import UnloadArticleButton from './UnloadArticleButton'

const UnloadArticle = ({
  publication: { initial_page_slug },
  history: { location: { pathname } },
}) => {
  const gridUrl = initial_page_slug ? '/blog' : '/'
  const homeUrl = `/${initial_page_slug}`
  let path
  if(!initial_page_slug && pathname !== gridUrl) {
    path = gridUrl // link articles to home
  } else if(initial_page_slug && pathname === gridUrl) {
    path = homeUrl // link grid to home
  } else if(initial_page_slug && pathname !== homeUrl) {
    path = gridUrl // link to grid in any article other than home
  } else {
    return null // render nothing otherwise
  }
  return <UnloadArticleButton path={path} />
}

export default withRouter(connector(['publication'])(UnloadArticle))
