import 'isomorphic-fetch'
import cookies from 'react-cookie'
import {OrderedMap} from 'immutable'

import types from '../actionTypes'
import {urls} from '../../../../settings'
import {jsonFromResponseOk, assertResponseOk} from '../../../../utils'

export const fetchGroups = publicationId => {
  return dispatch => {
    dispatch({type: types.LOAD_GROUPS_STARTED})
    return fetch(urls.launcher.groupListUrl(publicationId), {credentials: 'same-origin'})
      .then(response => response.json())
      .then(json => {
        const groups = OrderedMap(json.map(item => [item.id, item]))
        dispatch({type: types.SET_GROUPS, payload: {groups}})
        dispatch({type: types.LOAD_GROUPS_DONE})
      })
  }
}

export const loadGroup = (publicationId, groupId) => {
  return dispatch => {
    dispatch({type: types.LOAD_GROUP_STARTED})
    return fetch(urls.launcher.groupDetailUrl(publicationId, groupId), {credentials: 'same-origin'})
      .then(response => response.json())
      .then(json => dispatch({type: types.LOAD_GROUP_DONE, payload: {group: json}}))
  }
}

export const createGroup = (publicationId, name, description) => {
  return dispatch => {
    return fetch(urls.launcher.groupListUrl(publicationId), {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({
        publication: publicationId,
        name,
        description,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': cookies.load('csrftoken'),
      })
    })
      .then(response => response.json())
      .then(json => dispatch({type: types.APPEND_GROUP, payload: {group: json}}))
  }
}

export const deleteGroup = (publicationId, groupId) => {
  return dispatch => {
    dispatch({type: types.GROUP_DELETE_STARTED})
    return fetch(urls.launcher.groupDetailUrl(publicationId, groupId), {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: new Headers({
        'X-CSRFToken': cookies.load('csrftoken'),
      })
    })
      .then(assertResponseOk)
      .then(() => {
        dispatch({
          type: types.GROUP_DELETED,
          payload: {
            group: groupId,
          }
        })
      })
      .catch((err) => dispatch({
        type: types.GROUP_DELETE_FAILED,
        reason: err,
      }))
  }
}

export const editGroup = (publicationId, groupId, name, description) => dispatch => {
  dispatch({type: types.GROUP_EDIT_STARTED})
  return fetch(urls.launcher.groupDetailUrl(publicationId, groupId), {
    method: 'PUT',
    credentials: 'same-origin',
    body: JSON.stringify({
      publication: publicationId,
      name,
      description,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': cookies.load('csrftoken'),
    })
  })
    .then(jsonFromResponseOk)
    .then(group => dispatch({type: types.GROUP_EDITED, payload: group}))
    .catch(err => dispatch({type: types.GROUP_EDIT_FAILED, reason: err}))
}
