import types from '../../actionTypes'
import WebFont from 'webfontloader'
import {OrderedMap} from 'immutable'


export const updateTextItemContent = (drawingItemId, content) => ({type: types.UPDATE_TEXT_ITEM_CONTENT, drawingItemId, content})

export const setDrawingItemTextAlign = (drawingItemId, align) => ({type: types.SET_TEXT_ITEM_ALIGN, drawingItemId, align})

export const setTextItemFGColor = (drawingItemId, color) => ({type: types.SET_TEXT_ITEM_FG_COLOR, drawingItemId, color})

export const setTextItemBGColor = (drawingItemId, color) => ({type: types.SET_TEXT_ITEM_BG_COLOR, drawingItemId, color})

export const setTextItemFontFace = (drawingItemId, fontFace) => (dispatch, getState) => {
  const state = getState()
  const usedVariants = state.fonts.get(fontFace)
  let variants = state.main.fontList.get(fontFace).variants
  const fontVariant = variants.includes('regular') ? 'regular' : variants[0]

  if (usedVariants) {
    usedVariants.forEach(variant => {
      variants = variants.filter(value => value != variant)
    })
  }

  const postLoadCallback = () => {
    dispatch({type: types.SET_TEXT_ITEM_FONT_FACE, drawingItemId, fontFace, fontVariant})
    dispatch({type: types.SET_TEXT_ITEM_DEFAULT_FONT_FACE, fontFace, fontVariant})
  }

  if (variants.length > 0) {
    WebFont.load({
      google: {families: [fontFace + ":" + variants.join(',')]},
      active: () => {
        dispatch({type: types.SET_FONT_LOADED, fontFace, variants})
        postLoadCallback()
      }
    })
  } else {
    postLoadCallback()
  }
}

export const setTextItemFontVariant = (drawingItemId, font) => (dispatch) => {
  dispatch({type: types.SET_TEXT_ITEM_FONT_VARIANT, drawingItemId, fontVariant: font.variant})
  dispatch({type: types.SET_TEXT_ITEM_DEFAULT_FONT_FACE, fontFace: font.font, fontVariant: font.variant})
}

export const setTextItemFontSize = (drawingItemId, fontSize) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: types.SET_TEXT_ITEM_FONT_SIZE,
    drawingItemId, fontSize,
    baseWidth: state.properties.appliedDrawingAreaWidth
  })
  dispatch({type: types.SET_TEXT_ITEM_DEFAULT_FONT_SIZE, fontSize})
}

export const setTextAdaptability = (drawingItemId, textAdaptability) => ({type: types.SET_TEXT_ADAPTABILITY, drawingItemId, textAdaptability})

export const setTextItemBold = () => ({type: types.SET_TEXT_ITEM_BOLD})

export const setTextItemItalic = () => ({type: types.SET_TEXT_ITEM_ITALIC})

export const setTextItemUnderline = () => ({type: types.SET_TEXT_ITEM_UNDERLINE})

export const setTextItemStrikeThrough = () => ({type: types.SET_TEXT_ITEM_STRIKETHROUGH})

export const storeCurrentTextStyle = (payload) => ({type: types.STORE_CURRENT_TEXT_STYLE, payload})

export const setTextItemUrl = (url) => ({type: types.SET_TEXT_ITEM_URL, url})

export const resizeTextItemAndMoveBellowItems = (options) => (dispatch, getState) => {
  const state = getState()

  const selectedItem = state.items.get(options.id)
  const bellowItems = state.items
    .filter(item => item.pos[1] > selectedItem.end[1])
    .filter(item => item.screenSize == selectedItem.screenSize)
  const newHeight = options.value / options.relativeTo * 100
  const delta = newHeight - selectedItem.size[1]

  dispatch({
    type: types.SET_SPECIFIC_DRAWING_ITEM_DIMENSION,
    dimension: 'height',
    drawingItemId: options.id,
    value: newHeight,
    relativeTo: null
  })

  bellowItems.forEach(item => {
    dispatch({
      type: types.SET_SPECIFIC_DRAWING_ITEM_DIMENSION,
      dimension: 'top',
      drawingItemId: item.id,
      value: item.pos[1] + delta,
      relativeTo: null
    })
  })
}

export const loadUsedFonts = () => (dispatch, getState) => {
  const state = getState()
  const textItems = state.items.filter(item => item.type == 'text')

  if(textItems.count()) {
    const usedFonts = textItems
      .reduce((newList, item) => {
        const currentFont = state.fonts.get(item.textFontFace)
        if (currentFont && currentFont.includes(item.textFontVariant)) {
          return newList
        } else {
          return newList.update(item.textFontFace, variants => new Set(variants).add(item.textFontVariant))
        }
      }, OrderedMap())
      .map(variants => Array.from(variants))

    const fontsToLoad = usedFonts.map((variants, fontFace) => fontFace + ":" + variants.join(','))
    if(fontsToLoad.size) {
      WebFont.load({
        google: {families: fontsToLoad.valueSeq().toArray()},
        fontactive: (fontFace) => {
          dispatch({type: types.SET_FONT_LOADED, fontFace, variants: usedFonts.get(fontFace)})
        },
        active: () => {
          dispatch({type: types.SET_PREVIEW_FONTS_LOADED})
        }
      })
    }
  }
}

export const loadDefaultFonts = () => (dispatch) => {
  const defaultFonts = [
    {face: "Open Sans", variants: ['regular']},
    {face: "Bitter", variants: ['regular']}
  ]

  WebFont.load({
    google: {families: defaultFonts.map(item => item.face + ":" + item.variants.join(', '))},
    fontactive: (fontFace) => {
      const variants = defaultFonts.filter(font => font.face == fontFace)[0].variants
      dispatch({type: types.SET_FONT_LOADED, fontFace, variants})
    },
    active: () => {
      dispatch({type: types.SET_DEFAULT_FONTS_LOADED})
    }
  })
}

export const setHeadingLevel = (drawingItemId, headingLevel) => ({ type: types.HEADING_LEVEL_CHANGED, drawingItemId, headingLevel })
