import React from 'react'
import {withRouter} from 'react-router'
import connector from "player/connector"

@withRouter
@connector(['pages', 'options'])
export default class PreviousArticleButton extends React.Component {
  goBack = (event) => {
    event.preventDefault()
    this.props.history.push(this.path)
  }

  getLabel() {
    const { pages: { prev } } = this.props
    return prev ? 'Page' : 'Article'
  }

  render() {
    const {
      pages: { prev },
      options: { nextArticle },
      match: { params: { slug } },
    } = this.props

    let path = ''
    if(prev) {
      const prevPageIndex = this.props.pages.pages.keySeq().indexOf(prev)
      path = '/' + slug
      if(prevPageIndex > 0) {
        path += `/page-${prevPageIndex + 1}`
      }
    } else {
      path = `/${nextArticle}/last`
    }
    this.path = path

    if(nextArticle || prev) {
      return (
        <a
          id="backward-button"
          href={path}
          className="header-button navigation prev"
          onClick={this.goBack}
        >
          Previous <span>{this.getLabel()}</span>
        </a>
      )
    } else {
      return (
        <span
          id="backward-button"
          className="header-button navigation prev disabled"
        >
          Previous <span>{this.getLabel()}</span>
        </span>
      )
    }
  }
}
