import React from 'react'
import { useTranslation } from 'react-i18next'

const LoadMore = ({ onClick }) => {
  const { t } = useTranslation()
  const onClickHandler = (event) => {
    event.preventDefault()
    onClick()
  }

  return (
    <div
      className="load-more"
      onClick={onClickHandler}
    >
      <span>{t('Load More')}...</span>
    </div>
  )
}

export default LoadMore
