import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'


export default class DisplayDate extends React.Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div className="info">
        <div className="date">
          {this.props.date && moment(this.props.date).format('DD MMM YYYY')}
        </div>
      </div>
    )
  }
}
