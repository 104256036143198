import React, { Component } from 'react'
import PiwikReactRouter from 'piwik-react-router'
import { urls } from '../settings'

export default ComponentToWrap => {
  class PiwikReactRouterComponent extends Component {
    render() {
      // The publication is taken from the props, this way, it should be
      // inserted previously (e.g. a connect HOC must come before this one)
      const { publication: { piwik_site_id } } = this.props
      if (piwik_site_id && !window.piwikRouter) {
        window.piwikRouter = PiwikReactRouter({
          url: urls.player.piwikUrl,
          siteId: piwik_site_id,
        })
      }
      const props = {...this.props, piwikRouter: window.piwikRouter}
      return <ComponentToWrap {...props} />
    }
  }
  const displayName = ComponentToWrap.displayName || ComponentToWrap.name || 'Component'
  PiwikReactRouterComponent.displayName = `WithPiwikReactRouter(${displayName})`
  return PiwikReactRouterComponent
}
