import types from '../actionTypes'
import {OrderedMap} from 'immutable'

export default function fonts(state=OrderedMap(), action) {
  switch (action.type) {
    case types.SET_FONT_LOADED: {
      return state.update(action.fontFace, variants => action.variants.concat(variants))
    }
    default:
      return state
  }
}
