import React from 'react'

import connector from "player/connector"


@connector(['pages'])
export default class PageProgressBar extends React.Component {
  render() {
    const totalPages = this.props.pages.pages.size
    const currentIndex = this.props.pages.pages.keySeq().indexOf(this.props.pages.active)
    const progressPercentage = ((currentIndex + 1) / totalPages) * 100
    return (
      <div className="progress-bar-container">
        <div className="progress-bar" style={{width: `${progressPercentage}%`}}>
          <div className="label">Page {currentIndex + 1} of {totalPages}</div>
        </div>
      </div>
    )
  }
}
