import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'

import Cover from './Cover'
import Title from './Title'
import DisplayDate from './DisplayDate'


@withRouter
export default class ArticleItem extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
  }

  render() {
    const {content} = this.props
    return (
      <div className="article" onClick={this.handleClick}>
        <Cover img={content.cover} />
        <Title title={content.title} />
        <DisplayDate date={content.date} />
      </div>
    )
  }

  handleClick = () => {
    this.props.history.push(`/${this.props.content.slug}`)
  }
}
