export const uploadImage = (image, url, callback, onProgress=null) => {
  const body = new FormData()
  body.append('image', image)

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('post', url)
    xhr.onload = e => resolve(e.target.responseText)

    xhr.onerror = reject

    if (xhr.upload && onProgress)
      xhr.upload.onprogress = onProgress

    xhr.send(body)
  }).then(data => {
    callback(data)
  }).catch(err => {
    throw(err)
  })
}
