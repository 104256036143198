import React from 'react'

export default class Rectangle extends React.Component {
  getColorStyle = () => {
    const {bgColor, borderColor, borderSize} = this.props.item
    const style = {}

    if (bgColor) {
      const {r, g, b, a} = bgColor
      style.backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`
    }

    if (borderColor && borderSize) {
      const {r, g, b, a} = borderColor
      style.border = `${borderSize}px solid rgba(${r}, ${g}, ${b}, ${a})`
    }

    return style
  }

  render() {
    return <div className="fill-parent rectangle" style={this.getColorStyle()}></div>
  }
}
