import types from '../actionTypes'
import {urls} from '../../settings'

export const loadPublication = publicationId => dispatch => {
  dispatch({type: types.LOAD_PUBLICATION_STARTED})

  return fetch(urls.player.loadPublication(publicationId), {credentials: 'same-origin'})
    .then(response => response.json())
    .then(response => dispatch({type: types.LOAD_PUBLICATION_DONE, payload: {...response}}))
    .catch(() => dispatch({type: types.LOAD_PUBLICATION_FAILED}))
}
