import React from 'react'
import connector from 'player/connector'
import Modal from '../../../common/components/Modal'
import { withTranslation } from 'react-i18next'

@withTranslation()
@connector(['authentication', 'actions'])
export default class Login extends React.Component {
  constructor() {
    super()
    this.state = {
      username: '',
      password: '',
      failed: false,
    }
  }

  handleUsernameChange = e => {
    this.setState({username: e.target.value})
  }

  handlePasswordChange = e => {
    this.setState({password: e.target.value})
  }

  render() {
    const {t} = this.props
    const failMessage = t('Please enter matching user email and password. Note that both fields distinguish Uppercase and lowercase letters')

    return (
      <div className='modal-container login-modal-container'>
        <Modal closeModal={this.cancel}>
          <form method='POST' onSubmit={this.handleLoginSubmit}>
            <h3 className='form-title'>{t('Please Log in')}</h3>

            {this.state.failed && <div className="alert alert-danger">{failMessage}</div>}

            <div className="field">
              <label htmlFor="username">{t('Email')}</label>
              <input
                type='text'
                name='username'
                id='username'
                value={this.state.username}
                onChange={this.handleUsernameChange}
                required />
            </div>

            <div className="field">
              <label htmlFor="password">{t('Password')}</label>
              <input
                type='password'
                name='password'
                id='password'
                value={this.state.password}
                onChange={this.handlePasswordChange}
                required />
            </div>

            <button
              type="submit"
              className='btn submit'
              disabled={this.props.authentication.isFetching}
            >
              <span>{t('Login')}</span>
            </button>
          </form>
        </Modal>
      </div>
    )
  }

  cancel = () => this.props.actions.closeAuthenticationModal()

  handleLoginSubmit = event => {
    event.preventDefault()
    this.props.actions.login(this.state.username, this.state.password)
      .then(wasSuccessful => {
        if(wasSuccessful) {
          this.props.actions.closeAuthenticationModal()
        } else {
          this.setState({failed: true})
        }
      })
  }
}
