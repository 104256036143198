import React from 'react'
import PropTypes from 'prop-types'

import PreviewItem from '../drawing/PreviewItem'


export default class PreviewArea extends React.Component {
  static propTypes = {
    items: PropTypes.object.isRequired,
    placement: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    main: PropTypes.object.isRequired,
    pages: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.ItemClass = PreviewItem
    this.containerRef = null
  }

  componentDidMount() {
    if(this.containerRef) {
      // Execute only if container is defined to be compatible with shallow tests
      this.storeContainerSize()
    }
    this.props.actions.autoSetScreenSize()
    window.addEventListener("resize", this.handleOnResize)
  }

  storeContainerSize() {
    this.props.actions.storeDrawingAreaSize([
      this.containerRef.clientWidth,
      this.containerRef.clientHeight
    ])
  }

  componentWillUmount() {
    window.removeEventListener("resize", this.handleOnResize)
  }

  /*
  * Store drawing area width to display sizes according it, not the window.
  */
  handleOnResize = () => {
    this.props.actions.storeDrawingAreaSize([
      this.containerRef.clientWidth,
      this.containerRef.clientHeight
    ])
    this.props.actions.autoSetScreenSize()
    this.updateSelectedTextHeightToMatchContent()
  }

  componentDidUpdate(prevProps) {
    const shouldResizeTexts = (
      this.props.properties.previewFontsLoaded != prevProps.properties.previewFontsLoaded ||
      this.props.properties.pickedScreenSize != prevProps.properties.pickedScreenSize
    )
    if (shouldResizeTexts) {
      this.updateSelectedTextHeightToMatchContent()
    }
    const previewContainer = document.getElementById('preview-container')
    if (previewContainer && this.props.pages.active !== prevProps.pages.active) {
      previewContainer.scrollTop = 0
    }
  }

  /*
   * During a resize or screen swap, adjust the height of text editor to match his content.
   *
   * Do it only in selected texts once this adjustment occours automatically
   * when swap selection.
   */
  updateSelectedTextHeightToMatchContent = () => {
    this.getActiveItems()
      .filter(item => item.type == 'text' && item.selected)
      .forEach(item => {
        const itemComponent = this.refs['item-' + item.id]
        if(itemComponent) {
          const editor = itemComponent.refs.innerItem.refs.editor.editorContainer
          const scale = this.props.properties.appliedDrawingAreaWidth / item.textFontSizeOnWidth
          this.props.actions.setDrawingItemDimension({
            dimension: 'height',
            id: item.id,
            value: editor.clientHeight * scale,
            relativeTo: this.props.properties.appliedDrawingAreaWidth
          })
        }
      })
  }

  renderItems() {
    const Item = this.ItemClass
    return this.getActiveItems().valueSeq().toArray().map(item => (
      <Item
        key={item.id}
        ref={'item-' + item.id}
        item={item}
        main={this.props.main}
        options={this.props.options}
        properties={this.props.properties}
        items={this.props.items}
        actions={this.props.actions} />
    ))
  }

  getPickedWidth(props) {
    if (props.properties.pickedScreenSize != null) {
      return props.properties.pickedScreenSize[0]
    } else {
      return null
    }
  }

  getActiveItems() {
    const pickedWidth = this.getPickedWidth(this.props)
    return this.props.items
      .filter(item => !item.setToBeDeleted)
      .filter(item => item.page == this.props.pages.active)
      .filter(item => item.screenSize == pickedWidth)
  }

  getPageBackgroundColor() {
    const { pages: { pages, active } } = this.props
    if (!active) {
      return undefined
    }
    const { backgroundColor } = pages.get(active)
    return backgroundColor || undefined
  }

  getArticleBackgroundColor() {
    const { options: { projectBGColor } } = this.props
    return projectBGColor || undefined
  }

  render() {
    return (
      <div style={{ backgroundColor: this.getArticleBackgroundColor() }}>
        <div
          id="preview-container"
          ref={el => this.containerRef = el}
          className="drawing preview"
          style={{ backgroundColor: this.getPageBackgroundColor() }}
        >
          {this.renderItems()}
        </div>
      </div>
    )
  }
}
