import * as GenericActionTypes from '../../common/actionTypes'

// Tools actions
export const PICK_TOOL = 'PICK_TOOL'
export const UNPICK_TOOL = 'UNPICK_TOOL'

// Drawing item placement and selection
export const DO_PLACEMENT = 'DO_PLACEMENT'
export const SELECT_DRAWING_ITEM = 'SELECT_DRAWING_ITEM'
export const UNSELECT_ALL_DRAWING_ITEMS = 'UNSELECT_ALL_DRAWING_ITEMS'
export const MULTISELECT_DRAWING_ITEM = 'MULTISELECT_DRAWING_ITEM'
export const DELETE_SELECTED_ITEMS = 'DELETE_SELECTED_ITEMS'
export const SET_ITEM_TO_DELETE = 'SET_ITEM_TO_DELETE'

// Generic global actions
export const PROJECT_BG_COLOR_CHANGED = 'PROJECT_BG_COLOR_CHANGED'

// Drawing item dimension actions
export const SET_DRAWING_ITEM_DIMENSION = 'SET_DRAWING_ITEM_DIMENSION'
export const SET_SPECIFIC_DRAWING_ITEM_DIMENSION = 'SET_SPECIFIC_DRAWING_ITEM_DIMENSION'
export const RESET_TIMEOUT = 'RESET_TIMEOUT'

// Shape items
export const SET_SHAPE_ITEM_BG_COLOR = 'SET_SHAPE_ITEM_BG_COLOR'
export const SET_SHAPE_DEFAULT_COLOR = 'SET_SHAPE_DEFAULT_COLOR'
export const SET_SHAPE_ITEM_BORDER_COLOR = 'SET_SHAPE_ITEM_BORDER_COLOR'
export const SET_SHAPE_BORDER_SIZE = 'SET_SHAPE_BORDER_SIZE'
export const SET_SHAPE_DEFAULT_BORDER_SIZE = 'SET_SHAPE_DEFAULT_BORDER_SIZE'
export const SET_SHAPE_DEFAULT_BORDER_COLOR = 'SET_SHAPE_DEFAULT_BORDER_COLOR'

// Text items
export const UPDATE_TEXT_ITEM_CONTENT = 'UPDATE_TEXT_ITEM_CONTENT'
export const SET_TEXT_ITEM_ALIGN = 'SET_TEXT_ITEM_ALIGN'
export const SET_TEXT_ITEM_FG_COLOR = 'SET_TEXT_ITEM_FG_COLOR'
export const SET_TEXT_ITEM_BG_COLOR = 'SET_TEXT_ITEM_BG_COLOR'
export const SET_TEXT_ITEM_FONT_FACE = 'SET_TEXT_ITEM_FONT_FACE'
export const SET_TEXT_ITEM_FONT_SIZE = 'SET_TEXT_ITEM_FONT_SIZE'
export const SET_TEXT_ADAPTABILITY = 'SET_TEXT_ADAPTABILITY'
export const STORE_CURRENT_TEXT_STYLE = 'STORE_CURRENT_TEXT_STYLE'
export const SET_TEXT_ITEM_BOLD = 'SET_TEXT_ITEM_BOLD'
export const SET_TEXT_ITEM_ITALIC = 'SET_TEXT_ITEM_ITALIC'
export const SET_TEXT_ITEM_UNDERLINE = 'SET_TEXT_ITEM_UNDERLINE'
export const SET_TEXT_ITEM_STRIKETHROUGH = 'SET_TEXT_ITEM_STRIKETHROUGH'
export const SET_TEXT_ITEM_FONT_VARIANT = 'SET_TEXT_ITEM_FONT_VARIANT'
export const SET_TEXT_ITEM_DEFAULT_FONT_FACE = 'SET_TEXT_ITEM_DEFAULT_FONT_FACE'
export const SET_TEXT_ITEM_DEFAULT_FONT_SIZE = 'SET_TEXT_ITEM_DEFAULT_FONT_SIZE'
export const SET_PREVIEW_FONTS_LOADED = 'SET_PREVIEW_FONTS_LOADED'
export const SET_DEFAULT_FONTS_LOADED = 'SET_DEFAULT_FONTS_LOADED'
export const SET_FONT_LOADED = 'SET_FONT_LOADED'
export const SET_TEXT_ITEM_URL = 'SET_TEXT_ITEM_URL'
export const HEADING_LEVEL_CHANGED = 'HEADING_LEVEL_CHANGED'

// Image items
export const RESET_IMAGE_UPLOAD_PROGRESS = 'RESET_IMAGE_UPLOAD_PROGRESS'
export const TOGGLE_KEEP_ASPECT_RATIO = 'TOGGLE_KEEP_ASPECT_RATIO'
export const SET_IMAGE = 'SET_IMAGE'

// Complex Items
export const SET_YOUTUBE_URL = 'SET_YOUTUBE_URL'
export const SET_EMBED_CODE = 'SET_EMBED_CODE'

// Link and other general stuff
export const SET_ITEM_URL = 'SET_ITEM_URL'
export const IMAGE_ALT_TEXT_CHANGED = 'IMAGE_ALT_TEXT_CHANGED'
export const ITEM_FIXED_CHANGED = 'ITEM_FIXED_CHANGED'

// Drawing item data save
export const SAVE_STARTED = 'SAVE_STARTED'
export const SAVE = 'SAVE'
export const SAVE_DONE = 'SAVE_DONE'
export const SAVE_FAILED = 'SAVE_FAILED'

// Image item img object save
export const ADD_IMAGE_FILE = 'ADD_IMAGE_FILE'
export const SET_IMAGE_UPLOAD_PROGRESS = 'SET_IMAGE_UPLOAD_PROGRESS'
export const SET_IMAGE_ASPECT = 'SET_IMAGE_ASPECT'

// Keyboard action handlers
export const SET_KEY_DOWN = 'SET_KEY_DOWN'
export const SET_KEY_UP = 'SET_KEY_UP'

// App action handlers
export const ONGOING_FONT_DOWNLOAD = 'ONGOING_FONT_DOWNLOAD'
export const DONE_FONT_DOWNLOAD = 'DONE_FONT_DOWNLOAD'
export const FAILED_FONT_DOWNLOAD = 'FAILED_FONT_DOWNLOAD'
export const UPDATE_ITEMS_LIST = 'UPDATE_ITEMS_LIST'
export const ARTICLE_LOAD_ERROR = 'ARTICLE_LOAD_ERROR'
export const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE'
export const UNLOAD_ARTICLE = 'UNLOAD_ARTICLE'
export const UPDATE_IMAGES_LIST = 'UPDATE_IMAGES_LIST'
export const SHOW_ARTICLE_LIST = 'SHOW_ARTICLE_LIST'
export const HIDE_ARTICLE_LIST = 'HIDE_ARTICLE_LIST'

// Visibility action handlers
export const SET_IS_RESIZING = 'SET_IS_RESIZING'
export const SET_IS_DRAGGING = 'SET_IS_DRAGGING'
export const SET_IS_WRITING = 'SET_IS_WRITING'
export const UNSET_IS_RESIZING = 'UNSET_IS_RESIZING'
export const UNSET_IS_DRAGGING = 'UNSET_IS_DRAGGING'
export const UNSET_IS_WRITING = 'UNSET_IS_WRITING'
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE'
export const STORE_DRAWING_AREA_SIZE = 'STORE_DRAWING_AREA_SIZE'
export const SET_ITEMS_ORDER = 'SET_ITEMS_ORDER'

// Autosave action handlers
export const UPDATE_ITEM_SAVE_TIMER = 'UPDATE_ITEM_SAVE_TIMER'
export const SET_ITEM_DIRTY = 'SET_ITEM_DIRTY'

// Tabs action handlers
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'

// Article action handles
export const SET_OPTIONS_FIELD_VALUE = 'SET_OPTIONS_FIELD_VALUE'
export const DEFINE_ARTICLE_COVER = 'DEFINE_ARTICLE_COVER'
export const FETCH_ARTICLE_STARTED = 'FETCH_ARTICLE_STARTED'
export const FETCH_ARTICLE_DONE = 'FETCH_ARTICLE_DONE'
export const FETCHED_ARTICLE_OPTIONS = 'FETCHED_ARTICLE_OPTIONS'
export const CREATE_ARTICLE_PAGE = 'CREATE_ARTICLE_PAGE'
export const TOGGLE_PAGE_REMOVED = 'TOGGLE_PAGE_REMOVED'
export const TOGGLE_PAGE_LOCKED = 'TOGGLE_PAGE_LOCKED'
export const TOGGLE_PAGE_HIDDEN = 'TOGGLE_PAGE_HIDDEN'
export const SET_ARTICLE_PAGE_TITLE = 'SET_ARTICLE_PAGE_TITLE'
export const SET_PAGES_ORDER = 'SET_PAGES_ORDER'
export const PAGE_BACKGROUND_COLOR_CHANGED = 'PAGE_BACKGROUND_COLOR_CHANGED'
export const ARTICLE_PAGE_SELECTED = 'ARTICLE_PAGE_SELECTED'
export const ARTICLE_PAGES_FETCH_DONE = 'ARTICLE_PAGES_FETCH_DONE'
export const SET_OPTIONS_DIRTY = 'SET_OPTIONS_DIRTY'
export const UNSET_OPTIONS_DIRTY = 'UNSET_OPTIONS_DIRTY'
export const SET_OPTIONS_PUBLISH = 'SET_OPTIONS_PUBLISH'
export const SET_OPTIONS_UNPUBLISH = 'SET_OPTIONS_UNPUBLISH'
export const SET_OPTIONS_DELETE = 'SET_OPTIONS_DELETE'
export const SET_OPTIONS_UNDELETE = 'SET_OPTIONS_UNDELETE'

// Offline
export const STORE_ARTICLE_OPTIONS = 'STORE_ARTICLE_OPTIONS'
export const STORE_ITEMS_SIZE = 'STORE_ITEMS_SIZE'

export const OPEN_CURRENT_ARTICLE_PREVIEW_DONE = 'OPEN_CURRENT_ARTICLE_PREVIEW_DONE'
export const CLOSE_CURRENT_ARTICLE_PREVIEW_DONE = 'CLOSE_CURRENT_ARTICLE_PREVIEW_DONE'

export const PDF_IMPORT_OPENED = 'PDF_IMPORT_OPENED'
export const PDF_IMPORT_SENT = 'PDF_IMPORT_SENT'
export const PDF_IMPORT_CLOSED = 'PDF_IMPORT_CLOSED'

export default {
  PICK_TOOL,
  UNPICK_TOOL,
  DO_PLACEMENT,
  SELECT_DRAWING_ITEM,
  UNSELECT_ALL_DRAWING_ITEMS,
  MULTISELECT_DRAWING_ITEM,
  DELETE_SELECTED_ITEMS,
  SET_ITEM_TO_DELETE,
  SET_DRAWING_ITEM_DIMENSION,
  SET_SPECIFIC_DRAWING_ITEM_DIMENSION,
  RESET_TIMEOUT,
  SET_SHAPE_ITEM_BG_COLOR,
  SET_SHAPE_DEFAULT_COLOR,
  SET_SHAPE_ITEM_BORDER_COLOR,
  SET_SHAPE_BORDER_SIZE,
  SET_SHAPE_DEFAULT_BORDER_SIZE,
  SET_SHAPE_DEFAULT_BORDER_COLOR,
  RESET_IMAGE_UPLOAD_PROGRESS,
  UPDATE_TEXT_ITEM_CONTENT,
  SET_TEXT_ITEM_ALIGN,
  SET_TEXT_ITEM_FG_COLOR,
  SET_TEXT_ITEM_FONT_FACE,
  SET_TEXT_ITEM_FONT_SIZE,
  SET_TEXT_ADAPTABILITY,
  STORE_CURRENT_TEXT_STYLE,
  SET_TEXT_ITEM_BOLD,
  SET_TEXT_ITEM_ITALIC,
  SET_TEXT_ITEM_UNDERLINE,
  SET_TEXT_ITEM_STRIKETHROUGH,
  SET_TEXT_ITEM_FONT_VARIANT,
  SET_TEXT_ITEM_BG_COLOR,
  SET_TEXT_ITEM_DEFAULT_FONT_FACE,
  SET_TEXT_ITEM_DEFAULT_FONT_SIZE,
  SET_PREVIEW_FONTS_LOADED,
  SET_DEFAULT_FONTS_LOADED,
  SET_TEXT_ITEM_URL,
  HEADING_LEVEL_CHANGED,
  SET_YOUTUBE_URL,
  SET_EMBED_CODE,
  SET_ITEM_URL,
  IMAGE_ALT_TEXT_CHANGED,
  ITEM_FIXED_CHANGED,
  SET_FONT_LOADED,
  SAVE_STARTED,
  SAVE,
  SAVE_DONE,
  SAVE_FAILED,
  ADD_IMAGE_FILE,
  SET_IMAGE_UPLOAD_PROGRESS,
  TOGGLE_KEEP_ASPECT_RATIO,
  SET_IMAGE,
  SET_IMAGE_ASPECT,
  SET_KEY_DOWN,
  SET_KEY_UP,
  ONGOING_FONT_DOWNLOAD,
  DONE_FONT_DOWNLOAD,
  FAILED_FONT_DOWNLOAD,
  UPDATE_ITEMS_LIST,
  ARTICLE_LOAD_ERROR,
  SET_CURRENT_ARTICLE,
  UNLOAD_ARTICLE,
  UPDATE_IMAGES_LIST,
  SET_IS_RESIZING,
  SET_IS_DRAGGING,
  SET_IS_WRITING,
  UNSET_IS_RESIZING,
  UNSET_IS_DRAGGING,
  UNSET_IS_WRITING,
  SET_SCREEN_SIZE,
  STORE_DRAWING_AREA_SIZE,
  UPDATE_ITEM_SAVE_TIMER,
  SET_ITEM_DIRTY,
  SET_CURRENT_TAB,
  SET_ITEMS_ORDER,
  SET_OPTIONS_DIRTY,
  UNSET_OPTIONS_DIRTY,
  SET_OPTIONS_PUBLISH,
  SET_OPTIONS_UNPUBLISH,
  SET_OPTIONS_DELETE,
  SET_OPTIONS_UNDELETE,
  SET_OPTIONS_FIELD_VALUE,
  DEFINE_ARTICLE_COVER,
  FETCH_ARTICLE_STARTED,
  FETCH_ARTICLE_DONE,
  FETCHED_ARTICLE_OPTIONS,
  CREATE_ARTICLE_PAGE,
  TOGGLE_PAGE_REMOVED,
  TOGGLE_PAGE_LOCKED,
  TOGGLE_PAGE_HIDDEN,
  SET_ARTICLE_PAGE_TITLE,
  SET_PAGES_ORDER,
  PAGE_BACKGROUND_COLOR_CHANGED,
  ARTICLE_PAGE_SELECTED,
  ARTICLE_PAGES_FETCH_DONE,
  STORE_ITEMS_SIZE,
  OPEN_CURRENT_ARTICLE_PREVIEW_DONE,
  CLOSE_CURRENT_ARTICLE_PREVIEW_DONE,
  PDF_IMPORT_OPENED,
  PDF_IMPORT_SENT,
  PDF_IMPORT_CLOSED,
  PROJECT_BG_COLOR_CHANGED,
  ...GenericActionTypes
}
