import types from '../../actionTypes'
import {
  screenSizesToSave,
  arrowMovingSpeed,
  arrowMovingTurboSpeed,
} from '../../../settings'
import {
  isSelectedWriting,
  relativeDimension,
} from '../../../utils'
import uuid from 'uuid/v4'


export const doPlacement = (start, end, baseWidth) => (dispatch, getState) => {
  const state = getState()

  const shapeDefaults = {
    bgColor: state.properties.shapeInitialColor,
    borderSize: state.properties.shapeInitialBorderSize,
    borderColor: state.properties.shapeInitialBorderColor
  }

  const defaults = {
    ellipse: shapeDefaults,
    rectangle: shapeDefaults,
    text: {
      textFontFace: state.properties.textInitialFontFace,
      textFontVariant: state.properties.textInitialFontVariant,
      textFontSize: state.properties.textInitialFontSize,
      textFontSizeOnWidth: baseWidth
    }
  }

  if (state.placement.selectedTool == 'image') {
    const {id} = state.properties.selectedImage

    defaults.image = {
      imageId: id
    }
  }

  dispatch({type: types.UNSELECT_ALL_DRAWING_ITEMS})

  let parentKey = null
  let selected = true
  screenSizesToSave.forEach((screenSize, i) => {
    const key = uuid()
    dispatch({
      page: state.pages.active,
      type: types.DO_PLACEMENT,
      selectedTool: state.placement.selectedTool,
      start,
      end,
      baseWidth,
      defaults,
      screenSize,
      key,
      parentKey,
      selected,
    })

    if (i == 0) {
      parentKey = key
      selected = false
    }
  })

  dispatch({type: types.UNPICK_TOOL})
}

export const selectDrawingItem = (drawingItemId) => (dispatch, getState) => {
  const state = getState()

  if (state.keypresses.includes('Shift')) {
    dispatch({type: types.MULTISELECT_DRAWING_ITEM, drawingItemId})
  } else {
    dispatch({type: types.SELECT_DRAWING_ITEM, drawingItemId})
  }
}

export const unselectAllDrawingItems = () => ({type: types.UNSELECT_ALL_DRAWING_ITEMS})

export const deleteSelectedItems = () => (dispatch, getState) => {
  const items = getState().items

  if (isSelectedWriting(items)) {
    return
  }

  const selectedItems = items.filter(item => item.selected)
  selectedItems.forEach(item => {
    const parentKey = item.parentKey != null ? item.parentKey : item.id
    items
      .filter(item => item.parentKey == parentKey || item.id == parentKey)
      .forEach(item => dispatch({type: types.SET_ITEM_TO_DELETE, drawingItemId: item.id}))
  })
}

export const setItemToDelete = (drawingItemId) => (dispatch, getState) => {
  const state = getState()
  const currentItem = state.items.get(drawingItemId)
  const parentKey = currentItem.parentKey != null ? currentItem.parentKey : currentItem.id

  state.items
    .filter(item => item.parentKey == parentKey || item.id == parentKey)
    .forEach(item => dispatch({type: types.SET_ITEM_TO_DELETE, drawingItemId: item.id}))
}

export const setIsResizing = (id) => ({type: types.SET_IS_RESIZING, drawingItemId: id})
export const setIsDragging = (id) => ({type: types.SET_IS_DRAGGING, drawingItemId: id})
export const setIsWriting = (id) => ({type: types.SET_IS_WRITING, drawingItemId: id})
export const unsetIsResizing = (id) => ({type: types.UNSET_IS_RESIZING, drawingItemId: id})
export const unsetIsDragging = (id) => ({type: types.UNSET_IS_DRAGGING, drawingItemId: id})
export const unsetIsWriting = (id) => ({type: types.UNSET_IS_WRITING, drawingItemId: id})

/**
 * Dispatch the dimension update action now or after a timeout, depending on
 * an option, and reset any previously defined timeout.
 */
export const setDrawingItemDimension = (options) => (dispatch, getState) => {
  let relativeTo = null

  if(options.relativeTo) {
    relativeTo = options.relativeTo
  }

  const actions = [{
    type: types.SET_DRAWING_ITEM_DIMENSION,
    dimension: options.dimension,
    drawingItemId: options.id,
    value: options.value,
    relativeTo
  }]

  const state = getState()

  const item = state.items.get(options.id)

  if (item.keepAspect && (options.dimension == 'width' || options.dimension == 'height')) {
    let value, dimension

    if (options.dimension == 'width') {
      dimension = 'height'
      value = (item.size[1] * options.value) / item.size[0]
    } else {
      dimension = 'width'
      value = (item.size[0] * options.value) / item.size[1]
    }

    actions.push({
      type: types.SET_DRAWING_ITEM_DIMENSION,
      drawingItemId: options.id,
      dimension, value, relativeTo
    })
  }

  const dispatchDimensions = () => {
    actions.forEach(action => dispatch(action))
  }

  let timeoutId
  if(!options['hard']) {
    timeoutId = setTimeout(dispatchDimensions, 1000)
  } else {
    dispatchDimensions()
    timeoutId = null
  }

  dispatch({type: types.RESET_TIMEOUT, dimension: options.dimension, timeoutId})
}

export const setKeyDown = (key) => (dispatch, getState) => {
  if (!isSelectedWriting(getState().items)) {
    dispatch({type: types.SET_KEY_DOWN, key})
  }
}

export const setKeyUp = (key) => (dispatch, getState) => {
  if (!isSelectedWriting(getState().items)) {
    dispatch({type: types.SET_KEY_UP, key})
  }
}

export const toggleKeepAspectRatio = (drawingItemId) => ({type: types.TOGGLE_KEEP_ASPECT_RATIO, drawingItemId})

export const setScreenSize = (size) => ({type: types.SET_SCREEN_SIZE, size})

export const autoSetScreenSize = () => (dispatch, getState) => {
  const width = getState().properties.readDrawingAreaSize[0]
  let size
  if (width <= 414) {
    size = [414, 480]
  } else if(width <= 800) {
    size = [800, 800]
  } else {
    size = null
  }
  dispatch({type: types.SET_SCREEN_SIZE, size})
}

export const storeDrawingAreaSize = (size) => ({type: types.STORE_DRAWING_AREA_SIZE, size})

/*
 * Increment or decrement item position (when arrows are pressed).
 */
export const moveSelectedItems = (direction) => (dispatch, getState) => {
  const state = getState()
  const selectedItems = state.items.filter(item => item.selected)
  if(selectedItems.size == 0) {
    return
  }

  const isHorizontal = direction == 'Left' || direction == 'Right'
  const isShiftPressed = state.keypresses.includes('Shift')
  const absIncrement = isShiftPressed ? arrowMovingTurboSpeed : arrowMovingSpeed

  let incrementBy = 100 / window.innerWidth * absIncrement
  if(direction == 'Left' || direction == 'Up') {
    incrementBy = -1 * incrementBy
  }

  selectedItems.forEach(item => {
    dispatch({
      type: types.SET_DRAWING_ITEM_DIMENSION,
      dimension: isHorizontal ? 'left' : 'top',
      drawingItemId: item.id,
      value: item.pos[isHorizontal ? 0 : 1] + incrementBy
    })
  })
}

export const setItemsOrder = (items) => ({type: types.SET_ITEMS_ORDER, items})

export const horizontalObjectAlign = (items, position) => (dispatch) => {
  let sizeFactor = 0

  if (position == 'center') {
    sizeFactor = .5
  } else if (position == 'right') {
    sizeFactor = 1
  }

  items.forEach(item => {
    const value = (100 * sizeFactor) - (item.size[0] * sizeFactor)
    dispatch({type: types.SET_DRAWING_ITEM_DIMENSION, dimension: 'left', drawingItemId: item.id, value})
  })
}

export const verticalObjectAlign = (items, position) => (dispatch, getState) => {
  const state = getState()
  const {pickedScreenSize, readDrawingAreaSize, appliedDrawingAreaWidth} = state.properties

  let drawingAreaHeight

  if (pickedScreenSize) {
    drawingAreaHeight = pickedScreenSize[1]
  } else {
    drawingAreaHeight = readDrawingAreaSize[1]
  }

  drawingAreaHeight = drawingAreaHeight / appliedDrawingAreaWidth * 100

  let sizeFactor = 0

  if (position == 'middle') {
    sizeFactor = .5
  } else if (position == 'bottom') {
    sizeFactor = 1
  }

  items.forEach(item => {
    const value = (drawingAreaHeight * sizeFactor) - (item.size[1] * sizeFactor)
    dispatch({type: types.SET_DRAWING_ITEM_DIMENSION, dimension: 'top', drawingItemId: item.id, value})
  })
}

export const selectRange = (start, end, currentScreenWidth) => (dispatch, getState) => {
  const startVw = [
    relativeDimension(Math.min(start[0], end[0]), currentScreenWidth),
    relativeDimension(Math.min(start[1], end[1]), currentScreenWidth)
  ]

  const endVw = [
    relativeDimension(Math.max(start[0], end[0]), currentScreenWidth),
    relativeDimension(Math.max(start[1], end[1]), currentScreenWidth)
  ]

  const usedScreenSize = screenSizesToSave.includes(currentScreenWidth) ? currentScreenWidth : null

  dispatch({type: types.UNSELECT_ALL_DRAWING_ITEMS})

  getState().items
    .filter(item => item.screenSize == usedScreenSize)
    .filter(item => item.pos[0] >= startVw[0] && item.pos[1] >= startVw[1])
    .filter(item => item.end[0] <= endVw[0] && item.end[1] <= endVw[1])
    .forEach(item => dispatch({type: types.MULTISELECT_DRAWING_ITEM, drawingItemId: item.id}))
}

export const setItemUrl = (drawingItemId, url) => ({type: types.SET_ITEM_URL, drawingItemId, url})

export const setImageAltText = (drawingItemId, text) => ({type: types.IMAGE_ALT_TEXT_CHANGED, drawingItemId, text})

export const setIsFixed = (drawingItemId, isFixed) => ({ type: types.ITEM_FIXED_CHANGED, drawingItemId, isFixed })
