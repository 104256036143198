import types from '../actionTypes'


export const setOptionValue = (name, value) => ({type: types.SET_OPTIONS_FIELD_VALUE, payload: {name, value}})

export const setArticlePublished = () => ({type: types.SET_OPTIONS_PUBLISH})

export const setArticleUnpublished = () => ({type: types.SET_OPTIONS_UNPUBLISH})

export const setArticleDeleted = () => ({type: types.SET_OPTIONS_DELETE})

export const setArticleUndeleted = () => ({type: types.SET_OPTIONS_UNDELETE})
