// Action types used in all apps
const DEFINE_ARTICLE_COVER = 'DEFINE_ARTICLE_COVER'
const REGISTRATION_STARTED = 'REGISTRATION_STARTED'
const REGISTRATION_DONE = 'REGISTRATION_DONE'
const START_PAYMENT_PROFILE_FETCH = 'START_PAYMENT_PROFILE_FETCH'
const CURRENT_PAYMENT_PROFILE_FETCHED = 'CURRENT_PAYMENT_PROFILE_FETCHED'
const LOADING_ERROR = 'LOADING_ERROR'
const START_PAYMENT_COMPANIES_FETCH = 'START_PAYMENT_COMPANIES_FETCH'
const PAYMENT_COMPANIES_FETCHED = 'PAYMENT_COMPANIES_FETCHED'
const START_BILLING_SAVE = 'START_BILLING_SAVE'
const BILLING_UPDATED = 'BILLING_UPDATED'
const PRE_REGISTRATION_FETCH_START = 'PRE_REGISTRATION_FETCH_START'
const PRE_REGISTRATION_FETCHED = 'PRE_REGISTRATION_FETCHED'
const PRE_REGISTRATION_SAVE_START = 'PRE_REGISTRATION_SAVE_START'
const PRE_REGISTRATION_SAVED = 'PRE_REGISTRATION_SAVED'
const START_EMAIL_VALIDATION = 'START_EMAIL_VALIDATION'
const EMAIL_VALIDATED = 'EMAIL_VALIDATED'
const EMAIL_VALIDATION_ERROR = 'EMAIL_VALIDATION_ERROR'
const LOAD_ARTICLES_DONE = 'reader:LOAD_ARTICLES_DONE'
const CURRENT_PAYMENT_PROFILE_FETCH_FAILED = 'CURRENT_PAYMENT_PROFILE_FETCH_FAILED'
const START_PUBLICATION_SLUG_VALIDATION = 'START_PUBLICATION_SLUG_VALIDATION'
const PUBLICATION_SLUG_VALIDATED = 'PUBLICATION_SLUG_VALIDATED'
const PUBLICATION_SLUG_VALIDATION_ERROR = 'PUBLICATION_SLUG_VALIDATION_ERROR'
const START_ARTICLE_SLUG_VALIDATION = 'START_ARTICLE_SLUG_VALIDATION'
const ARTICLE_SLUG_VALIDATED = 'ARTICLE_SLUG_VALIDATED'
const ARTICLE_SLUG_VALIDATION_ERROR = 'ARTICLE_SLUG_VALIDATION_ERROR'
const AMPSTORY_SLUG_VALIDATION_STARTED = 'AMPSTORY_SLUG_VALIDATION_STARTED'
const AMPSTORY_SLUG_VALIDATION_DONE = 'AMPSTORY_SLUG_VALIDATION_DONE'
const AMPSTORY_SLUG_VALIDATION_FAILED = 'AMPSTORY_SLUG_VALIDATION_FAILED'

export {
  DEFINE_ARTICLE_COVER,
  REGISTRATION_STARTED,
  REGISTRATION_DONE,
  START_PAYMENT_PROFILE_FETCH,
  CURRENT_PAYMENT_PROFILE_FETCHED,
  LOADING_ERROR,
  START_PAYMENT_COMPANIES_FETCH,
  PAYMENT_COMPANIES_FETCHED,
  START_BILLING_SAVE,
  BILLING_UPDATED,
  PRE_REGISTRATION_FETCH_START,
  PRE_REGISTRATION_FETCHED,
  PRE_REGISTRATION_SAVE_START,
  PRE_REGISTRATION_SAVED,
  START_EMAIL_VALIDATION,
  EMAIL_VALIDATED,
  EMAIL_VALIDATION_ERROR,
  LOAD_ARTICLES_DONE,
  CURRENT_PAYMENT_PROFILE_FETCH_FAILED,
  START_PUBLICATION_SLUG_VALIDATION,
  PUBLICATION_SLUG_VALIDATED,
  PUBLICATION_SLUG_VALIDATION_ERROR,
  START_ARTICLE_SLUG_VALIDATION,
  ARTICLE_SLUG_VALIDATED,
  ARTICLE_SLUG_VALIDATION_ERROR,
  AMPSTORY_SLUG_VALIDATION_STARTED,
  AMPSTORY_SLUG_VALIDATION_DONE,
  AMPSTORY_SLUG_VALIDATION_FAILED,
}
