import 'isomorphic-fetch'
import {OrderedMap} from 'immutable'
import settings from '../../settings'
import types from '../actionTypes'

export const downloadFontList = () => {
  return dispatch => {
    dispatch({type: types.ONGOING_FONT_DOWNLOAD})
    return fetch(settings.fontsListUrl(), {method: 'GET'})
      .then(response => response.json())
      .then(json => {
        const payload = OrderedMap(json.map(row => [row.name, row]))
        dispatch({type: types.DONE_FONT_DOWNLOAD, payload})
      })
      .catch(() => dispatch({type: types.FAILED_FONT_DOWNLOAD}))
  }
}

export const fetchArticle = (url, dispatch) => {
  dispatch({ type: types.FETCH_ARTICLE_STARTED })
  return fetch(url, {credentials: 'same-origin'})
    .then(response => response.json())
    .then(json => fetchArticleSideEffect(dispatch, json))
}

/*
 * Dispatch the needed actions for the json output from fetchArticle.
 */
export const fetchArticleSideEffect = (dispatch, json) => {
  dispatch({ type: types.SET_CURRENT_ARTICLE, articleId: json.id })
  dispatch({
    type: types.FETCHED_ARTICLE_OPTIONS,
    payload: {
      id: json.id,
      cover: json.cover,
      date: json.date,
      description: json.description,
      groups: json.groups,
      projectBGColor: json.background_color,
      isDeleted: json.is_deleted,
      isPublished: json.is_published,
      isRestricted: json.is_restricted,
      isStandalone: json.is_standalone,
      nextArticle: json.next_article,
      previousArticle: json.previous_article,
      publication: json.publication,
      slug: json.slug,
      status: json.status,
      title: json.title,
      updatedAt: json.updated_at,
    }
  })

  dispatch({
    type: types.ARTICLE_PAGES_FETCH_DONE,
    pages: OrderedMap(json.pages.map(page => [page.id, {
      id: page.id,
      title: page.title,
      index: page.index,
      isRemoved: page.is_removed,
      isLocked: page.is_locked,
      isHidden: page.is_hidden,
      nameIndex: page.name_index,
      backgroundColor: page.background_color,
    }]))
  })

  const items = OrderedMap(json.items.map(item => [
    item.key,
    {id: item.key, page: item.page, ...JSON.parse(item.data)}
  ]))
  dispatch({type: types.UPDATE_ITEMS_LIST, items})

  dispatch({
    type: types.UPDATE_IMAGES_LIST,
    images: OrderedMap(json.images.map(image => [image.id, image]))
  })

  dispatch({ type: types.FETCH_ARTICLE_DONE })

  return json
}

export const downloadArticle = (articleId, version='preview') => {
  return dispatch => {
    return fetchArticle(settings.articleUrl(articleId, version), dispatch)
      .catch(() => dispatch({type: types.ARTICLE_LOAD_ERROR}))
  }
}

export const unloadArticle = () => ({type: types.UNLOAD_ARTICLE})

export const openCurrentArticlePreview = () => ({type: types.OPEN_CURRENT_ARTICLE_PREVIEW_DONE})

export const closeCurrentArticlePreview = () => ({type: types.CLOSE_CURRENT_ARTICLE_PREVIEW_DONE})

export const setProjectBGColor = (color) => ({type: types.PROJECT_BG_COLOR_CHANGED, payload: color})
