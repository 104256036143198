import React from 'react'

export default class Ellipse extends React.Component {
  getStyle = () => {
    const {bgColor, borderColor, borderSize} = this.props.item
    const style = {}

    if (bgColor) {
      const {r, g, b, a} = bgColor
      style.fill =  `rgba(${r}, ${g}, ${b}, ${a})`
    }

    if (borderColor && borderSize) {
      const {r, g, b, a} = borderColor
      style.strokeWidth = `${borderSize}px`
      style.stroke = `rgba(${r}, ${g}, ${b}, ${a})`
    }

    return style
  }

  render() {
    const svgStyle = {
      padding: `${this.props.item.borderSize / 2}px`,
      boxSizing: "border-box"
    }

    return (
      <svg height="100%" width="100%" style={svgStyle}>
        <ellipse cx="50%" cy="50%" rx="50%" ry="50%" style={this.getStyle()} />
      </svg>
    )
  }
}
