import types from 'layouter/actionTypes'
import launcherTypes from 'launcher/actionTypes'
import playerTypes from 'player/actionTypes'
import readersTypes from 'common/modules/Readers/actionTypes'

export const incrementTypes = [
  types.FETCH_ARTICLE_STARTED,
  types.REGISTRATION_STARTED,
  types.START_PAYMENT_PROFILE_FETCH,
  types.START_BILLING_SAVE,
  types.START_EMAIL_VALIDATION,
  launcherTypes.ISSUE_CREATE_STARTED,
  launcherTypes.SUBSCRIPTION_UPDATE_STARTED,
  playerTypes.LOAD_CONTENTS_STARTED,
  readersTypes.LOAD_GROUPS_STARTED,
  readersTypes.LOAD_GROUP_STARTED,
  readersTypes.LOAD_READERS_STARTED,
  readersTypes.GROUP_DELETE_STARTED,
  readersTypes.GROUP_EDIT_STARTED,
  readersTypes.READERS_DELETE_STARTED,
  readersTypes.READERS_MOVE_STARTED,
  readersTypes.READERS_CSV_IMPORT_STARTED,
]

export const decrementTypes = [
  types.FETCH_ARTICLE_DONE,
  types.REGISTRATION_DONE,
  types.CURRENT_PAYMENT_PROFILE_FETCHED,
  types.CURRENT_PAYMENT_PROFILE_FETCH_FAILED,
  types.BILLING_UPDATED,
  types.LOADING_ERROR,
  types.EMAIL_VALIDATED,
  types.EMAIL_VALIDATION_ERROR,
  launcherTypes.ISSUE_CREATE_DONE,
  launcherTypes.ISSUE_CREATE_FAILED,
  launcherTypes.SUBSCRIPTION_UPDATED,
  launcherTypes.SUBSCRIPTION_UPDATE_FAIL,
  playerTypes.LOAD_CONTENTS_DONE,
  playerTypes.LOAD_CONTENTS_FAILED,
  readersTypes.LOAD_GROUPS_DONE,
  readersTypes.LOAD_GROUP_DONE,
  readersTypes.LOAD_READERS_DONE,
  readersTypes.GROUP_DELETED,
  readersTypes.GROUP_DELETE_FAILED,
  readersTypes.GROUP_EDITED,
  readersTypes.GROUP_EDIT_FAILED,
  readersTypes.READERS_DELETED,
  readersTypes.READERS_DELETE_FAILED,
  readersTypes.READERS_MOVED,
  readersTypes.READERS_MOVE_FAILED,
  readersTypes.READERS_CSV_IMPORT_DONE,
  readersTypes.READERS_CSV_IMPORT_FAILED,
]

export default function loading(state=0, action) {
  if(incrementTypes.indexOf(action.type) != -1) {
    return state + 1
  }

  if(decrementTypes.indexOf(action.type) != -1) {
    if (state == 0) {
      return state
    } else {
      return state - 1
    }
  }

  return state
}
