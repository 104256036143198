// Layouter constants
export const screenSizesToSave = [null, 800, 414]
export const arrowMovingSpeed = 2
export const arrowMovingTurboSpeed = 10


// Launcher constants
export const SAVE_TIMER = 3000


// API URL Constants
export const articleUrl = (articleId, version) => (`/api/v1/articles/${articleId}/` + (version ? `?version=${version}` : ''))
export const articleCoverUrl = (articleId) => `/api/v1/articles/${articleId}/cover/`
export const articleSaveImageUrl = (articleId) => `/api/v1/articles/${articleId}/images/`
export const fontsListUrl = () => '/api/v1/fonts/'
export const publicationsListUrl = () => '/api/v1/publications/'
export const publicationsArticlesListUrl = (publicationId) => `/api/v1/publications/${publicationId}/articles/`
export const articleListUrl = (publicationId) => `/launcher/#/${publicationId}`
export const articlePreviewUrl = (articleId) => `/articles/${articleId}/preview/`
export const logoutUrl = () => `/logout/`
export const publicationIconImageUpload = (publicationId) => `/publications/${publicationId}/icon/upload/`
export const publicationLogoImageUpload = (publicationId) => `/publications/${publicationId}/logo/upload/`
export const publicationSplashImageUpload = (publicationId) => `/publications/${publicationId}/splash/upload/`
export const publicationBannerImageUpload = (publicationId) => `/publications/${publicationId}/banner/upload/`
export const publicationSaveUrl = (publicationId) => `/api/v1/publications/${publicationId}/save/`
export const publicationVerifySenderEmailUrl = (publicationId) => `/api/v1/publications/${publicationId}/verify-sender-email/`
export const listCategoriesUrl = () => '/api/v1/categories/'
export const userProfileDetail = () => '/api/v1/profiles/my-profile/'
export const listTemplatesURL = () => '/api/v1/templates/'
export const currentPublisher = () => '/api/v1/publishers/current/'
export const plansListUrl = () => '/api/v1/plans/'
export const activeContractUrl = () => '/api/v1/contracts/latest/'
export const contractPDFViewUrl = contractId => `/contracts/${contractId}/`
export const fetchAllPaymentCompanies = () => '/api/v1/payment_companies/'
export const preRegistrationURL = () => '/api/v1/registrations/'
export const emailValidationURL = () => '/api/v1/profiles/validate-email/'
export const publicationSlugValidationURL = (publicationId) => `/api/v1/publications/${publicationId}/slug-validation/`
export const articleSlugValidationURL = (publicationId, articleId) => `/api/v1/publications/${publicationId}/articles/${articleId}/slug-validation/`

export default {
  articleUrl,
  articleCoverUrl,
  articleSaveImageUrl,
  fontsListUrl,
  publicationsListUrl,
  publicationsArticlesListUrl,
  articleListUrl,
  articlePreviewUrl,
  logoutUrl,
  publicationIconImageUpload,
  publicationLogoImageUpload,
  publicationSplashImageUpload,
  publicationBannerImageUpload,
  publicationSaveUrl,
  publicationVerifySenderEmailUrl,
  listCategoriesUrl,
  userProfileDetail,
  listTemplatesURL,
  currentPublisher,
  plansListUrl,
  activeContractUrl,
  contractPDFViewUrl,
  fetchAllPaymentCompanies,
  preRegistrationURL,
  emailValidationURL,
  publicationSlugValidationURL,
  articleSlugValidationURL,
}

/*
 * New approach: export a prop url to make it possible to do something like:
 *
 *   urls.reader.loadPublication
 */
export const urls = {
  general: {
    fontsListUrl: () => '/api/v1/fonts/',
  },
  launcher: {
    ampStoriesListUrl: () => '/api/v1/amp-stories/',
    ampStoryCover: storyId => `/api/v1/amp-stories/${storyId}/cover/`,

    issueLink: (publicationId, issueId) => `https://pdf.magtab.com/editor/titulo/${publicationId}/edicoes/${issueId}/editar`,
    issuesEndpoint: () => '/api/v1/issues/',
    issuePrice: () => '/api/v1/issue-prices/',
    buyNewCredits: () => '/api/v1/issue-credits/',

    groupListUrl: publicationId => `/api/v1/publications/${publicationId}/groups/`,
    groupDetailUrl: (publicationId, groupId) => `/api/v1/publications/${publicationId}/groups/${groupId}/`,
    readerListUrl: (publicationId, groupId) => `/api/v1/publications/${publicationId}/groups/${groupId}/readers/`,
    userListUrl: () => `/api/v1/users/`,
    reportDataUrl: publicationId => `/api/v1/reports/${publicationId}/`,
    reportPagesDataUrl: (publicationId, contentType, contentId, startingFrom, endingOn) =>
      `/api/v1/reports/${publicationId}/${contentType}/${contentId}/?date=${startingFrom},${endingOn}`,
    legacyReportDataUrl: publicationId => `/api/v1/issue-pageview/?publication=${publicationId}`,
    legacyReportPagesDataUrl: (publicationId, contentType, contentId, startingFrom, endingOn) =>
      `/api/v1/issue-pageview/pages/?publication=${publicationId}&issue=${contentId}&date=${startingFrom},${endingOn}`,

    readersCSVUploadUrl: (publicationId, groupId) => `/api/v1/publications/${publicationId}/groups/${groupId}/import-csv/`,
    readersSamplesCSVFile: () => '/sample-readers-import.csv',
    readersCSVExportUrl: (groupId) => `/readers/export-csv/${groupId}/`,

    emailNotificationListUrl: () => `/api/v1/email-notifications/`,
    emailNotificationRemoveUrl: () => `/api/v1/email-notifications/remove/`,
    emailNotificationDetailUrl: emailId => `/api/v1/email-notifications/${emailId}/`,
    sendTestEmails: emailId => `/api/v1/email-notifications/${emailId}/simulate/`,
    emailRecipientsCountUrl: () => `/api/v1/email-notifications/count/`,

    pushNotificationListUrl: () => `/api/v1/push-notifications/`,
    pushNotificationRemoveUrl: () => `/api/v1/push-notifications/remove/`,
    pushNotificationDetailUrl: pushId => `/api/v1/push-notifications/${pushId}/`,
    pushTargetDevicesCountUrl: () => `/api/v1/push-notifications/count/`,

    contentListUrl: () => `/api/v1/contents/`,

    subscriptions: () => `/api/v1/subscriptions/`,
    vindiPaymentProfile: (publicationId) => `/api/v1/subscriptions/vindi-payment-profile/` + (publicationId ? `?publication=${publicationId}` : ''),
  },
  player: {
    login: () => '/api/v1/login/',
    logout: () => '/api/v1/logout/',
    loadCurrentUser: () => '/api/v1/profiles/my-profile/',
    loadPublication: publicationId => `/api/v1/publications/${publicationId}/`,
    loadArticles: publicationId => `/api/v1/publications/${publicationId}/public-articles/`,
    loadPublicArticle: (publicationId, slug) => `/api/v1/publications/${publicationId}/public-articles/${slug}/`,
    piwikUrl: 'https://analytics.magtab.com',
  },
  ampstories: {
    layouter: {
      createStoryUrl: (publicationId) => `/publications/${publicationId}/new-amp-story/`,
      launcherBackLink: (publicationId) => `/launcher/#/${publicationId}/stories`,
      previewUrl: (storyId) => `/amp-stories/${storyId}/preview/`,
      storyDetail: (storyId) => `/api/v1/amp-stories/${storyId}/`,
      storyImageList: (storyId) => `/api/v1/amp-stories/${storyId}/images/`,
      storyMovieList: (storyId) => `/api/v1/amp-stories/${storyId}/movies/`,
      storyMovieDetail: (storyId, movieId) => `/api/v1/amp-stories/${storyId}/movies/${movieId}/`,
      slugValidationUrl: () => `/api/v1/amp-stories/slug-validation/`,
      animationTypesListUrl: () => `/api/v1/amp-animation-types/`,
    },
  },
  signup: {
    publisherListUrl: () => '/api/v1/publishers/',
  },
}

export const appName = 'webapp'
export const appVersion = 1
