import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router'
import connector from "player/connector"

const MainHead = ({
  publication: { name, short_description, logo, marketing_url },
  location: { pathname },
}) => {
  if (!name || pathname !== "/") {
    return null
  }

  return (
    <Helmet>
      <title>{name}</title>
      <meta name="description" content={short_description} />
      <link rel="canonical" href={marketing_url} />
      <meta property="og:title" content={name} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={marketing_url} />
      <meta property="og:description" content={short_description} />
      {logo && <meta property="og:image" content={logo} />}
    </Helmet>
  )
}

export default withRouter(connector(['publication'])(MainHead))
