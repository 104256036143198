import {
  EditorState,
  SelectionState,
} from 'draft-js'

export function moveSelectionToStart(editorState) {
  const content = editorState.getCurrentContent()
  const firstBlock = content.getFirstBlock()
  return EditorState.acceptSelection(editorState, SelectionState.createEmpty(firstBlock.getKey()))
}
