import uuid from 'uuid/v4'
import types from '../actionTypes'


export const setActivePage = pageId => dispatch => {
  dispatch({type: types.ARTICLE_PAGE_SELECTED, payload: pageId})
  dispatch({type: types.UNSELECT_ALL_DRAWING_ITEMS})
}

export const createPage = () => (dispatch, getState) => {
  const state = getState()
  const key = uuid()
  const latestPage = state.pages.pages.maxBy(page => page.nameIndex)
  dispatch({
    type: types.CREATE_ARTICLE_PAGE,
    payload: {
      page: {
        id: key,
        title: '',
        index: state.pages.pages.size + 1,
        isRemoved: false,
        isLocked: false,
        isHidden: false,
        nameIndex: latestPage ? latestPage.nameIndex + 1 : 0,
        backgroundColor: '',
      }
    }
  })
}

export const togglePageRemoved = pageId => ({type: types.TOGGLE_PAGE_REMOVED, payload: {pageId}})

export const togglePageLocked = pageId => ({type: types.TOGGLE_PAGE_LOCKED, payload: {pageId}})

export const togglePageHidden = pageId => ({type: types.TOGGLE_PAGE_HIDDEN, payload: {pageId}})

export const setPageTitle = (pageId, title) => ({type: types.SET_ARTICLE_PAGE_TITLE, payload: {pageId, title}})

export const setPagesOrder = (pages) => ({type: types.SET_PAGES_ORDER, payload: pages})

export const setPageBackgroundColor = (pageId, color) => ({ type: types.PAGE_BACKGROUND_COLOR_CHANGED, payload: { pageId, color } })
