import types from '../actionTypes'
import {urls} from '../../settings'
import {fetchArticle} from '../../layouter/actionCreators/app'
import {loadUsedFonts} from '../../layouter/actionCreators/drawingItem/text'


export const fetchArticleFromSlug = (publicationId, articleSlug) => (dispatch, getState) => {
  return fetchArticle(urls.player.loadPublicArticle(publicationId, articleSlug), dispatch)
    .then(response => {
      dispatch({type: types.HIDE_ARTICLE_LIST})
      loadUsedFonts()(dispatch, getState)
      return response
    })
}
