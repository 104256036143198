import * as GenericActionTypes from '../../common/actionTypes'

// Publications
export const FETCH_PUBLICATIONS = 'FETCH_PUBLICATIONS'
export const SET_CURRENT_PUBLICATION = 'SET_CURRENT_PUBLICATION'
export const UNSET_CURRENT_PUBLICATION = 'UNSET_CURRENT_PUBLICATION'

// Publication Images Upload
export const DEFINE_PUBLICATION_ICON = 'DEFINE_PUBLICATION_ICON'
export const DEFINE_PUBLICATION_LOGO = 'DEFINE_PUBLICATION_LOGO'
export const DEFINE_PUBLICATION_SPLASH = 'DEFINE_PUBLICATION_SPLASH'
export const DEFINE_PUBLICATION_BANNER = 'DEFINE_PUBLICATION_BANNER'

// Publication persistence action types
export const SYNC_LOCAL_PUBLICATION = 'SYNC_LOCAL_PUBLICATION'
export const PUBLICATION_INFO_SAVED = 'PUBLICATION_INFO_SAVED'
export const SAVE_PUBLICATION_FAILED = 'SAVE_PUBLICATION_FAILED'
export const PUBLICATION_SENDER_EMAIL_VERIFIED = 'PUBLICATION_SENDER_EMAIL_VERIFIED'
export const SET_SAVE_PUBLICATION_TIMEOUT = 'SET_SAVE_PUBLICATION_TIMEOUT'
export const SYNC_PUBLICATION_VALIDATION = 'SYNC_PUBLICATION_VALIDATION'

export const SUBMIT_PUBLICATION_TO_APPS_STARTED = 'SUBMIT_PUBLICATION_TO_APPS_STARTED'
export const SUBMIT_PUBLICATION_TO_APPS_DONE = 'SUBMIT_PUBLICATION_TO_APPS_DONE'
export const SUBMIT_PUBLICATION_TO_APPS_FAILED = 'SUBMIT_PUBLICATION_TO_APPS_FAILED'

// Properties
export const LOAD_CATEGORIES_LIST = 'LOAD_CATEGORIES_LIST'
export const STORE_CURRENT_USER_PROFILE = 'STORE_CURRENT_USER_PROFILE'
export const SYNCH_USER_PROFILE_STATE = 'SYNCH_USER_PROFILE_STATE'
export const SAVE_CURRENT_USER_PROFILE = 'SAVE_CURRENT_USER_PROFILE'
export const SET_SAVE_PROFILE_TIMEOUT = 'SET_SAVE_PROFILE_TIMEOUT'
export const SET_IS_PROFILE_VALID = 'SET_IS_PROFILE_VALID'
export const LOAD_TEMPLATES_LIST = 'LOAD_TEMPLATES_LIST'
export const SAVE_PROFILE_FIELD_ERROR = 'SAVE_PROFILE_FIELD_ERROR'
export const STORE_PUBLISHER = 'STORE_PUBLISHER'
export const SET_PUBLISHER_DIRTY = 'SET_PUBLISHER_DIRTY'
export const SAVE_PUBLISHER = 'SAVE_PUBLISHER'
export const SET_SAVE_PUBLISHER_TIMEOUT = 'SET_SAVE_PUBLISHER_TIMEOUT'

// Articles
export const DEFINE_ARTICLE_COVER = 'DEFINE_ARTICLE_COVER'
export const SET_CURRENT_PUBLICATION_ARTICLES = 'SET_CURRENT_PUBLICATION_ARTICLES'
export const APPEND_CURRENT_PUBLICATION_ARTICLES = 'APPEND_CURRENT_PUBLICATION_ARTICLES'
export const STORE_ARTICLES_NEXT_PAGE = 'STORE_ARTICLES_NEXT_PAGE'

// Validations
export const UPDATE_BILLING_VALIDATION = 'UPDATE_BILLING_VALIDATION'
export const INITIALIZE_BILLING_VALIDATION = 'INITIALIZE_BILLING_VALIDATION'

// Billing
export const UPDATE_BILLING = 'UPDATE_BILLING'
export const INITIALIZE_BILLING = 'INITIALIZE_BILLING'

// Issues
export const STORE_PUBLICATION_ISSUES = 'STORE_PUBLICATION_ISSUES'
export const STORE_PUBLICATION_ISSUES_NEXT_PAGE = 'STORE_PUBLICATION_ISSUES_NEXT_PAGE'
export const ISSUE_CREATE_STARTED = 'ISSUE_CREATE_STARTED'
export const ISSUE_CREATE_DONE = 'ISSUE_CREATE_DONE'
export const ISSUE_CREATE_FAILED = 'ISSUE_CREATE_FAILED'
export const ISSUE_PRICE_FETCHED = 'ISSUE_PRICE_FETCHED'
export const ISSUE_PRICE_FETCH_FAILED = 'ISSUE_PRICE_FETCH_FAILED'
export const ISSUES_CREDITS_PURCHASE_STARTED = 'ISSUES_CREDITS_PURCHASE_STARTED'
export const ISSUES_CREDITS_PURCHASED = 'ISSUES_CREDITS_PURCHASED'
export const ISSUES_CREDITS_PURCHASE_FAILED = 'ISSUES_CREDITS_PURCHASE_FAILED'
export const ISSUES_CREDITS_PURCHASE_RESET = 'ISSUES_CREDITS_PURCHASE_RESET'

// Publication plan update
export const SUBSCRIPTION_UPDATE_STARTED = 'SUBSCRIPTION_UPDATE_STARTED'
export const SUBSCRIPTION_UPDATED = 'SUBSCRIPTION_UPDATED'
export const SUBSCRIPTION_UPDATE_FAIL = 'SUBSCRIPTION_UPDATE_FAIL'

export default {
  ...GenericActionTypes,
  FETCH_PUBLICATIONS,
  SET_CURRENT_PUBLICATION,
  UNSET_CURRENT_PUBLICATION,
  DEFINE_PUBLICATION_ICON,
  DEFINE_PUBLICATION_LOGO,
  DEFINE_PUBLICATION_SPLASH,
  DEFINE_PUBLICATION_BANNER,
  SYNC_LOCAL_PUBLICATION,
  PUBLICATION_INFO_SAVED,
  SAVE_PUBLICATION_FAILED,
  PUBLICATION_SENDER_EMAIL_VERIFIED,
  SET_SAVE_PUBLICATION_TIMEOUT,
  SYNC_PUBLICATION_VALIDATION,

  SUBMIT_PUBLICATION_TO_APPS_STARTED,
  SUBMIT_PUBLICATION_TO_APPS_DONE,
  SUBMIT_PUBLICATION_TO_APPS_FAILED,

  LOAD_CATEGORIES_LIST,
  STORE_CURRENT_USER_PROFILE,
  SYNCH_USER_PROFILE_STATE,
  SAVE_CURRENT_USER_PROFILE,
  SET_SAVE_PROFILE_TIMEOUT,
  SET_IS_PROFILE_VALID,
  LOAD_TEMPLATES_LIST,
  SAVE_PROFILE_FIELD_ERROR,
  STORE_PUBLISHER,
  SET_PUBLISHER_DIRTY,
  SAVE_PUBLISHER,
  SET_SAVE_PUBLISHER_TIMEOUT,

  DEFINE_ARTICLE_COVER,
  SET_CURRENT_PUBLICATION_ARTICLES,
  APPEND_CURRENT_PUBLICATION_ARTICLES,
  STORE_ARTICLES_NEXT_PAGE,

  UPDATE_BILLING_VALIDATION,
  INITIALIZE_BILLING_VALIDATION,
  UPDATE_BILLING,
  INITIALIZE_BILLING,

  STORE_PUBLICATION_ISSUES,
  STORE_PUBLICATION_ISSUES_NEXT_PAGE,
  ISSUE_CREATE_STARTED,
  ISSUE_CREATE_DONE,
  ISSUE_CREATE_FAILED,
  ISSUE_PRICE_FETCHED,
  ISSUE_PRICE_FETCH_FAILED,
  ISSUES_CREDITS_PURCHASE_STARTED,
  ISSUES_CREDITS_PURCHASED,
  ISSUES_CREDITS_PURCHASE_FAILED,
  ISSUES_CREDITS_PURCHASE_RESET,

  SUBSCRIPTION_UPDATE_STARTED,
  SUBSCRIPTION_UPDATED,
  SUBSCRIPTION_UPDATE_FAIL,
}
