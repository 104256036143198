import types from '../actionTypes'
import {urls} from '../../settings'


export const loadContents = publicationId => dispatch => {
  dispatch({type: types.LOAD_CONTENTS_STARTED})

  return fetch(`${urls.launcher.contentListUrl()}?publication=${publicationId}&page_size=20&type=article&type=ampstory`, {credentials: 'same-origin'})
    .then(response => response.json())
    .then(response => {
      dispatch({type: types.CONTENT_LIST_RESET})
      dispatch({type: types.LOAD_CONTENTS_DONE, payload: {...response}})
    })
    .catch(() => dispatch({type: types.LOAD_CONTENTS_FAILED}))
}

export const loadContentsNextPage = () => (dispatch, getState) => {
  const {contents} = getState()
  return fetch(contents.nextPage, {credentials: 'same-origin'})
    .then(response => response.json())
    .then(response => {
      dispatch({type: types.LOAD_CONTENTS_DONE, payload: {...response}})
    })
}
