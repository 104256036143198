import types from '../actionTypes'
import {OrderedMap} from 'immutable'
import {
  relativeDimension,
  replicateAlways,
  replicateUntilChanged
} from '../../utils/'


const defaults = (params={}, selectedTool) => {
  const localDefaults = {
    text: {
      textAdaptability: 'SCALE_TO_FIT',
    },
    image: {
      keepAspect: true,
    },
    ellipse: {
      borderColor: {r: 0, g: 0, b: 0, a: 1},
      borderSize: 0
    },
    rectangle: {
      borderColor: {r: 0, g: 0, b: 0, a: 1},
      borderSize: 0
    }
  }
  return Object.assign({}, localDefaults[selectedTool], params[selectedTool])
}


function getDrawingItemDimensionState(item, action) {
  let mergeState
  let value = action.value

  if(action.relativeTo) {
    value = relativeDimension(value, action.relativeTo)
  }

  if(action.dimension == 'left') {
    mergeState = {
      pos: [value, item.pos[1]],
      end: [item.end[0] + value - item.pos[0], item.end[1]]
    }
  } else if(action.dimension == 'top') {
    mergeState = {
      pos: [item.pos[0], value],
      end: [item.end[0], item.end[1] + value - item.pos[1]]
    }
  } else if(action.dimension == 'width') {
    mergeState = {
      size: [value, item.size[1]],
      end: [item.end[0] + value - item.size[0], item.end[1]]
    }
  } else if(action.dimension == 'height') {
    mergeState = {
      size: [item.size[0], value],
      end: [item.end[0], item.end[1] + value - item.size[1]]
    }
  }

  return mergeState
}

const cleanTemporaryStatus = {isResizing: false, isDragging: false, isWriting: false}

export default function items(state=OrderedMap(), action) {
  switch (action.type) {
    case types.DO_PLACEMENT: {
      let min
      if(state.size == 0) {
        min = 1
      } else {
        min = Math.min( ...state.valueSeq().toArray().map(item => item.itemOrder) )
      }
      return state.set(
        action.key,
        Object.assign({}, {
          id: action.key,
          page: action.page,
          parentKey: action.parentKey,
          type: action.selectedTool,
          selected: action.selected,
          keepAspect: false,
          isDirty: true,
          hasChanged: false,
          isResizing: false,
          isDragging: false,
          isWriting: false,
          screenSize: action.screenSize,
          itemOrder: min - 1,
          pos: [
            relativeDimension(Math.min(action.start[0], action.end[0]), action.baseWidth),
            relativeDimension(Math.min(action.start[1], action.end[1]), action.baseWidth)
          ],
          end: [
            relativeDimension(Math.max(action.start[0], action.end[0]), action.baseWidth),
            relativeDimension(Math.max(action.start[1], action.end[1]), action.baseWidth)
          ],
          size: [
            relativeDimension(Math.abs(action.end[0] - action.start[0]), action.baseWidth),
            relativeDimension(Math.abs(action.end[1] - action.start[1]), action.baseWidth)
          ]
        }, defaults(action.defaults, action.selectedTool))
      )
    }

    case types.SET_DRAWING_ITEM_DIMENSION: {
      const item = state.get(action.drawingItemId)
      const mergeState = getDrawingItemDimensionState(item, action)
      return replicateUntilChanged(item.id, mergeState, state)
    }

    case types.SET_SPECIFIC_DRAWING_ITEM_DIMENSION: {
      const item = state.get(action.drawingItemId)
      const mergeState = getDrawingItemDimensionState(item, action)
      return state.set(action.drawingItemId, Object.assign({}, item, mergeState))
    }

    case types.TOGGLE_KEEP_ASPECT_RATIO:
      return replicateAlways(action.drawingItemId, {keepAspect: !state.get(action.drawingItemId).keepAspect}, state)

    case types.SELECT_DRAWING_ITEM:
      return state.map((item, key) => {
        if (key == action.drawingItemId) {
          return Object.assign({}, item, {selected: true})
        } else {
          return Object.assign({}, item, {selected: false}, cleanTemporaryStatus)
        }
      })

    case types.MULTISELECT_DRAWING_ITEM:
      return state.update(action.drawingItemId, item =>
        Object.assign({}, item, {selected: !item.selected})
      )

    case types.UNSELECT_ALL_DRAWING_ITEMS:
      return state
        .filter(item => !item.deleteItem)
        .map(item => Object.assign({}, item, {selected: false}, cleanTemporaryStatus))

    case types.SET_IS_RESIZING:
      return state.update(action.drawingItemId, item => Object.assign({}, item, {isResizing: true}))

    case types.SET_IS_DRAGGING:
      return state.update(action.drawingItemId, item => Object.assign({}, item, {isDragging: true}))

    case types.SET_IS_WRITING:
      return state.update(action.drawingItemId, item => Object.assign({}, item, {isWriting: true}))

    case types.UNSET_IS_RESIZING:
      return state.update(action.drawingItemId, item => Object.assign({}, item, {isResizing: false}))

    case types.UNSET_IS_DRAGGING:
      return state.update(action.drawingItemId, item => Object.assign({}, item, {isDragging: false}))

    case types.UNSET_IS_WRITING:
      return state.update(action.drawingItemId, item => Object.assign({}, item, {isWriting: false}))

    case types.UPDATE_TEXT_ITEM_CONTENT:
      return replicateAlways(action.drawingItemId, {content: action.content}, state)

    case types.SET_TEXT_ITEM_FONT_FACE:
      return replicateAlways(action.drawingItemId,
        {textFontFace: action.fontFace, textFontVariant: action.fontVariant}, state)

    case types.SET_TEXT_ITEM_FONT_SIZE:
      return replicateUntilChanged(
        action.drawingItemId,
        {textFontSize: action.fontSize, textFontSizeOnWidth: action.baseWidth},
        state
      )

    case types.SET_TEXT_ADAPTABILITY:
      return replicateUntilChanged(action.drawingItemId, {textAdaptability: action.textAdaptability}, state)

    case types.SET_TEXT_ITEM_FONT_VARIANT:
      return replicateAlways(action.drawingItemId, {textFontVariant: action.fontVariant}, state)

    case types.SET_TEXT_ITEM_ALIGN:
      return replicateAlways(action.drawingItemId, {alignment: action.align}, state)

    case types.SET_SHAPE_ITEM_BG_COLOR:
      return replicateAlways(action.drawingItemId, {bgColor: action.color}, state)

    case types.SET_SHAPE_ITEM_BORDER_COLOR:
      return replicateAlways(action.drawingItemId, {borderColor: action.color}, state)

    case types.SET_ITEM_DIRTY:
      return state.update(action.drawingItemId, item =>
        Object.assign({}, item, {isDirty: true})
      )

    case types.SAVE:
      return state
        .filter(item => !item.setToBeDeleted)
        .map(item => Object.assign({}, item, {isDirty: false}))

    case types.UPDATE_ITEMS_LIST:
      return action.items

    case types.SET_ITEM_TO_DELETE:
      return replicateAlways(action.drawingItemId, {isDirty: true, setToBeDeleted: true}, state)

    case types.SET_IMAGE_ASPECT: {
      const item = state.get(action.drawingItemId)
      if (!item.imageAspectSet) {
        return replicateAlways(action.drawingItemId, {size: action.size, imageAspectSet: true}, state)
      } else {
        return state
      }
    }

    case types.SET_SHAPE_BORDER_SIZE:
      return replicateAlways(action.drawingItemId, {borderSize: action.size}, state)

    case types.SET_ITEMS_ORDER:
      return state.mergeWith(
        (oldVal, newVal) => ({...oldVal, itemOrder: newVal}),
        action.items,
      )

    case types.SET_YOUTUBE_URL:
      return replicateAlways(action.drawingItemId, {youtubeUrl: action.url}, state)

    case types.SET_EMBED_CODE:
      return replicateAlways(action.drawingItemId, {embedCode: action.code}, state)

    case types.SET_ITEM_URL:
      return replicateAlways(action.drawingItemId, {link: action.url}, state)

    case types.IMAGE_ALT_TEXT_CHANGED:
      return replicateAlways(action.drawingItemId, {imageAltText: action.text}, state)

    case types.ITEM_FIXED_CHANGED:
      return replicateUntilChanged(action.drawingItemId, {isFixed: action.isFixed}, state)

    case types.SET_SCREEN_SIZE: {
      const selectedItems = state.filter(item => item.selected).map(item => item.parentKey || item.id).valueSeq().toArray()
      const usedWidth = action.size ? action.size[0] : null

      return state.map(item => {
        if (item.selected && item.screenSize != usedWidth) {
          return Object.assign({}, item, {selected: false})
        } else if ((selectedItems.includes(item.parentKey) || selectedItems.includes(item.id)) && item.screenSize == usedWidth) {
          return Object.assign({}, item, {selected: true})
        } else {
          return item
        }
      })
    }

    case types.HEADING_LEVEL_CHANGED:
      return replicateAlways(action.drawingItemId, { headingLevel: action.headingLevel }, state)

    default:
      return state
  }
}
