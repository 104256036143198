import types from '../actionTypes'

const defaultState = {
  isAuthenticated: false,
  isFetching: false,
  isModalVisible: false,
}

export default (state=defaultState, action) => {
  switch(action.type) {
    case types.AUTHENTICATION_MODAL_OPENED:
      return {...state, isModalVisible: true}

    case types.AUTHENTICATION_MODAL_CLOSED:
      return {...state, isModalVisible: false}

    case types.LOGIN_STARTED:
    case types.LOGOUT_STARTED:
      return {...state, isFetching: true}

    case types.LOGIN_DONE:
      return {...state, isFetching: false, isAuthenticated: true}

    case types.LOGOUT_DONE:
      return {...state, isFetching: false, isAuthenticated: false}

    case types.LOGIN_FAILED:
    case types.LOGOUT_FAILED:
      return {...state, isFetching: false}

    default:
      return state
  }
}
