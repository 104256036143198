import React from 'react'
import PropTypes from 'prop-types'

import Cover from './Cover'
import Title from './Title'
import DisplayDate from './DisplayDate'


export default class AmpstoryItem extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
  }

  render() {
    const {content} = this.props
    return (
      <a className="article" href={`/${content.slug}`}>
        <Cover img={content.cover} />
        <Title title={content.title} />
        <DisplayDate date={content.date} />
      </a>
    )
  }
}
