import React from 'react'
import {Helmet} from "react-helmet"

const ArticleHead = ({
  options: { title, description, cover, slug, publication: { marketing_url } },
  pages: { pages, active }
}) => {
  const index = pages.keySeq().findIndex(key => key === active) + 1

  const urlSuffix = index > 1 ? `/page-${index}` : ''
  const fullUrl = `${marketing_url}${slug}${urlSuffix}`

  const titleSuffix = index > 1 ? ` (Page ${index})` : ''
  const fullTitle = `${title}${titleSuffix}`

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={fullUrl} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:description" content={description} />
      {cover && <meta property="og:image" content={cover} />}
    </Helmet>
  )
}

export default ArticleHead
