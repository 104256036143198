import React from 'react'
import PropTypes from 'prop-types'
import { getYoutubeVideoId } from '../../../utils'

class Embed extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    children: PropTypes.func.isRequired
  }

  render() {
    return (
      <div className="fill-parent">
        {!this.props.previewMode &&
          <div className="embed-overlay" style={this.getOverlayStyle()}></div>}
        {!this.props.item.isResizing && this.props.children()}
        <div className="embed-background fill-parent"></div>
      </div>
    )
  }

  getOverlayStyle() {
    return {
      width: this.props.item.size[0] + 'vw',
      height: this.props.item.size[1] + 'vw'
    }
  }
}

export const YoutubeEmbed = props => {
  const youtubeUrl = props.item.youtubeUrl
  let videoId = null
  if(youtubeUrl) {
    videoId = getYoutubeVideoId(youtubeUrl)
  }
  return (
    <Embed {...props}>{() => (videoId &&
      <iframe
        width="100%"
        height="100%"
        src={'https://www.youtube.com/embed/' + videoId}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    )}</Embed>
  )
}

export const GenericEmbed = (props) => (
  <Embed {...props}>{() => (
    props.item.embedCode &&
      <div dangerouslySetInnerHTML={{__html: props.item.embedCode}}></div>
  )}</Embed>
)
