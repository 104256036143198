import 'isomorphic-fetch'
import cookies from 'react-cookie'

import types from '../actionTypes'
import {urls} from '../../settings'
import {jsonFromResponseOk} from '../../utils'
import {loadContents} from './contents'

export const openAuthenticationModal = () => ({type: types.AUTHENTICATION_MODAL_OPENED})

export const closeAuthenticationModal = () => ({type: types.AUTHENTICATION_MODAL_CLOSED})

export const login = (username, password) => (dispatch, getState) => {
  dispatch({type: types.LOGIN_STARTED})
  const state = getState()
  const data = new FormData()
  data.append('username', username)
  data.append('password', password)
  data.append('publication', state.publication.id)
  return fetch(urls.player.login(state.publication.id), {
    method: 'POST',
    credentials: 'same-origin',
    headers: new Headers({
      'X-CSRFToken': cookies.load('csrftoken'),
    }),
    body: data,
  })
    .then(jsonFromResponseOk)
    .then(json => {
      loadContents(state.publication.id)(dispatch)

      dispatch({type: types.LOGIN_DONE, payload: {user: json.user}})
      return true
    })
    .catch(err => {
      dispatch({type: types.LOGIN_FAILED, reason: err})
      return false
    })
}

export const logout = () => (dispatch, getState) => {
  dispatch({type: types.LOGOUT_STARTED})
  return fetch(urls.player.logout(), {
    method: 'POST',
    credentials: 'same-origin',
    headers: new Headers({
      'X-CSRFToken': cookies.load('csrftoken'),
    }),
  })
    .then(response => {
      if(response.status < 200 || response.status >= 300) {
        throw (response.status + ' error')
      } else {
        return response
      }
    })
    .then(() => {
      const state = getState()
      loadContents(state.publication.id)(dispatch)

      dispatch({type: types.LOGOUT_DONE})
      return true
    })
    .catch(err => {
      dispatch({type: types.LOGOUT_FAILED, reason: err})
      return false
    })
}

export const loadCurrentUser = () => dispatch => {
  dispatch({type: types.LOGIN_STARTED})

  return fetch(urls.player.loadCurrentUser(), {credentials: 'same-origin'})
    .then(jsonFromResponseOk)
    .then(json => dispatch({type: types.LOGIN_DONE, payload: {user: json.user}}))
    .catch(err => dispatch({type: types.LOGIN_FAILED, reason: err}))
}
