import * as genericActionTypes from '../../common/actionTypes'

export default {
  AUTHENTICATION_MODAL_CLOSED: 'AUTHENTICATION_MODAL_CLOSED',
  AUTHENTICATION_MODAL_OPENED: 'AUTHENTICATION_MODAL_OPENED',
  LOGIN_STARTED: 'LOGIN_STARTED',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_DONE: 'LOGIN_DONE',
  LOGOUT_STARTED: 'LOGOUT_STARTED',
  LOGOUT_FAILED: 'LOGOUT_FAILED',
  LOGOUT_DONE: 'LOGOUT_DONE',

  LOAD_PUBLICATION_STARTED: 'reader:LOAD_PUBLICATION_STARTED',
  LOAD_PUBLICATION_DONE: 'reader:LOAD_PUBLICATION_DONE',
  LOAD_PUBLICATION_FAILED: 'reader:LOAD_PUBLICATION_FAILED',
  SHOW_ARTICLE_LIST: 'SHOW_ARTICLE_LIST',
  HIDE_ARTICLE_LIST: 'HIDE_ARTICLE_LIST',

  LOAD_CONTENTS_STARTED: 'reader:LOAD_CONTENTS_STARTED',
  LOAD_CONTENTS_DONE: 'reader:LOAD_CONTENTS_DONE',
  LOAD_CONTENTS_FAILED: 'reader:LOAD_CONTENTS_FAILED',
  CONTENT_LIST_RESET: 'reader:CONTENT_LIST_RESET',

  ...genericActionTypes
}
