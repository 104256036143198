import React from 'react'
import connector from "player/connector"
import LoadMore from '../../../common/components/LoadMore'
import List from './List'

@connector(['contents', 'actions'])
export default class Articles extends React.Component {
  render() {
    return (
      <div className="player-articles">
        <div className="articles-wrapper">
          <div className="articles">
            <List />
            {this.props.contents.nextPage &&
              <LoadMore onClick={this.props.actions.loadContentsNextPage} />}
          </div>
        </div>
      </div>
    )
  }
}
