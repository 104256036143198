import types from '../actionTypes'
import moment from 'moment'


const initialState = {
  title: '',
  description: '',
  date: null,
  isOptionsDirty: false,
  mediaURL: '',
  isDeleted: false,
  isPublished: false,
  isRestricted: false,
  isStandalone: false,
  groupIds: [],
  publication: {},
  status: {},
  cover: null,
  nextArticle: null,
  previousArticle: null,
  slug: '',
  hasSaveError: false,
  projectBGColor: '',
}

export default function options(state=initialState, action) {
  switch (action.type) {

    case types.SET_OPTIONS_FIELD_VALUE: // Define the value of any field on this reducer
      return Object.assign({}, state, {[action.payload.name]: action.payload.value})

    case types.DEFINE_ARTICLE_COVER:
      return Object.assign({}, state, {cover: action.cover})

    case types.SET_OPTIONS_DIRTY:
      return Object.assign({}, state, {isOptionsDirty: true})

    case types.UNSET_OPTIONS_DIRTY:
      return Object.assign({}, state, {isOptionsDirty: false})

    case types.SET_OPTIONS_PUBLISH:
      return Object.assign({}, state, {isPublished: true})

    case types.SET_OPTIONS_UNPUBLISH:
      return Object.assign({}, state, {isPublished: false})

    case types.SET_OPTIONS_DELETE:
      return Object.assign({}, state, {isDeleted: true})

    case types.SET_OPTIONS_UNDELETE:
      return Object.assign({}, state, {isDeleted: false})

    case types.FETCHED_ARTICLE_OPTIONS:
      return Object.assign(
        {},
        state,
        action.payload,
        {date: action.payload.date ? moment(action.payload.date) : null}
      )

    case types.SAVE:
      return {...state, ...action.payload}

    case types.SAVE_FAILED:
      return {...state, hasSaveError: true}

    case types.SAVE_DONE:
      return {...state, hasSaveError: false}

    case types.PROJECT_BG_COLOR_CHANGED:
      return { ...state, projectBGColor: action.payload }

    default:
      return state
  }
}
