import types from '../../actionTypes'

export const setImageToAdd = (image) => ({type: types.SET_IMAGE, image})

export const setImageAspect = (drawingItemId, width, height) => (dispatch, getState) => {
  const item = getState().items.get(drawingItemId)
  const size = [
    item.size[0],
    item.size[0] * height / width
  ]

  dispatch({type: types.SET_IMAGE_ASPECT, drawingItemId, size})
}
