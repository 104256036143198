import 'es5-shim'
import 'es6-shim'
import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import 'i18n'
import store from './store'
import Player from './components/Player'

const container = document.getElementById('Player')
const publicationId = parseInt(container.dataset.publicationid)

require('../assets/sass/player.scss')
require('../assets/sass/preview.scss')

render(
  <Provider store={store}>
    <Player publicationId={publicationId} />
  </Provider>,
  container
)
