import React from 'react'
import PropTypes from 'prop-types'


export default class Image extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    main: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
  }

  handleImageLoad = (event) => {
    const {naturalWidth, naturalHeight} = event.currentTarget
    this.props.actions.setImageAspect(this.props.item.id, naturalWidth, naturalHeight)
  }

  render() {
    const {imageList} = this.props.main
    const image = imageList.get(this.props.item.imageId)
    return (
      <div className="fill-parent center-both">
        {/* Render the image conditionally to avoid a blank image, due delay in image retrieve from list. */}
        {image && (
          <img
            className="fill-parent"
            src={image.url}
            onLoad={this.handleImageLoad}
            alt={this.props.item.imageAltText || null}
          />
        )}
      </div>
    )
  }
}
