import React from 'react'
import connector from 'player/connector'
import Login from './Login'
import Logout from './Logout'

@connector(['authentication', 'actions'])
export default class SubscriberButton extends React.Component {
  render() {
    console.log('render subs')
    const {
      authentication: { isModalVisible, isAuthenticated },
      actions: { openAuthenticationModal },
    } = this.props
    return (
      <div className="header-button-wrapper">
        <button
          type="button"
          className={'header-button navigation auth ' + (isAuthenticated ? 'logout' : 'login')}
          onClick={openAuthenticationModal}
        >
          {isAuthenticated ? 'Logout' : 'Login'}
        </button>
        {isModalVisible && !isAuthenticated && <Login />}
        {isModalVisible && isAuthenticated && <Logout />}
      </div>
    )
  }
}
