import React from 'react'
import {
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  ContentState,
  CharacterMetadata,
  EditorState,
  CompositeDecorator,
} from 'draft-js'
import { Map } from 'immutable'
import { Link as RouterLink } from 'react-router-dom'
import { getTarget, getTargetSuffixedUrl } from './linkTarget'

/**
 * Returns a ContentState to test Draft.js Editor of a DrawingItem.
 */
export function getRawContentFromText(text) {
  const blocksFromHTML = convertFromHTML('<div>' + text + '</div>')
  const rawContent = convertToRaw(ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  ))
  return rawContent
}

export function filterRawContentKey(rawContent) {
  delete rawContent.blocks[0]['key']
  return rawContent
}

/**
 * Returns the rawContent for the given EditorState, without the key.
 *
 * Suppress the key to make it easy in tests to compare data.
 */
export function getRawContentFromState(state) {
  const rawContent = convertToRaw(state.getCurrentContent())
  return filterRawContentKey(rawContent)
}

/**
 * Return the current content state without any styling in current selection
 */
export function removeSelectionStyle(editorState, filterFunction = null) {
  var contentState = editorState.getCurrentContent()
  var selectionState = editorState.getSelection()

  var blockMap = contentState.getBlockMap()
  var startKey = selectionState.getStartKey()
  var startOffset = selectionState.getStartOffset()
  var endKey = selectionState.getEndKey()
  var endOffset = selectionState.getEndOffset()

  var newBlocks = blockMap.skipUntil(function (_, k) {
    return k === startKey
  }).takeUntil(function (_, k) {
    return k === endKey
  }).concat(Map([[endKey, blockMap.get(endKey)]])).map(function (block, blockKey) {
    var sliceStart
    var sliceEnd

    if (startKey === endKey) {
      sliceStart = startOffset
      sliceEnd = endOffset
    } else {
      sliceStart = blockKey === startKey ? startOffset : 0
      sliceEnd = blockKey === endKey ? endOffset : block.getLength()
    }

    var chars = block.getCharacterList()
    var current
    while (sliceStart < sliceEnd) {
      current = chars.get(sliceStart)

      var stylesToRemove = current.getStyle()

      if (filterFunction != null) {
        stylesToRemove = stylesToRemove.filter(filterFunction)
      }
      stylesToRemove.forEach((style) => {
        chars = chars.set(sliceStart, CharacterMetadata.removeStyle(current, style))
      })
      sliceStart++
    }
    return block.set('characterList', chars)
  })

  return EditorState.push(editorState,
    contentState.merge({
      blockMap: blockMap.merge(newBlocks),
      selectionBefore: selectionState,
      selectionAfter: selectionState
    })
  )
}

export function getStyleFgColor(currentStyle) {
  const style = currentStyle
    .filter(item => item.startsWith('fg:rgba'))
    .map(value => {
      const color = value.match(/\d{1,3}/g).map(value => parseInt(value))
      return {r: color[0], g: color[1], b: color[2], a: color[3]}
    })

  if (style.length > 0) {
    return style[0]
  } else {
    return {r: 0, g: 0, b: 0, a: 1}
  }
}

export function getStyleBgColor(currentStyle) {
  const style = currentStyle
    .filter(item => item.startsWith('bg:rgba'))
    .map(value => {
      const color = value.match(/\d{1,3}/g).map(value => parseInt(value))
      return {r: color[0], g: color[1], b: color[2], a: color[3]}
    })

  if (style.length > 0) {
    return style[0]
  } else {
    return {r: 255, g: 255, b: 255, a: 0}
  }
}

function selectedStyles(styles) {
  return {
    color: getStyleFgColor(styles),
    bgColor: getStyleBgColor(styles),
    isBold: styles.includes('BOLD'),
    isItalic: styles.includes('ITALIC'),
    isUnderline: styles.includes('UNDERLINE'),
    isStrikeThrough: styles.includes('STRIKE_THROUGH')
  }
}

export function editorStateFromContent(content) {
  return EditorState.createWithContent(convertFromRaw(content))
}

export function getTextStyleFromSelection(editorState) {
  const styles = editorState.getCurrentInlineStyle().toArray()
  return selectedStyles(styles)
}

export function getSelectionLink(editorState) {
  const startKey = editorState.getSelection().getStartKey()
  const selectedBlock = editorState.getCurrentContent().getBlockForKey(startKey)
  const linkKey = selectedBlock.getEntityAt(editorState.getSelection().anchorOffset)
  if(linkKey) {
    const linkInstance = editorState.getCurrentContent().getEntity(linkKey)
    return linkInstance.getData().url
  } else {
    return ''
  }
}

/**
 * Translate supplied inlineStyles to CSS styles.
 */
export function getEditorSelectionCssStyle(style) {
  const output = {}

  if (style.has('BOLD')) { output.fontWeight = 'bold' }
  if (style.has('ITALIC')) { output.fontStyle = 'italic' }

  const textDecorations = []
  if (style.has('UNDERLINE')) { textDecorations.push('underline') }
  if (style.has('STRIKE_THROUGH')) { textDecorations.push('line-through') }
  if (textDecorations.length) { output.textDecoration = textDecorations.join(' ') }

  const fgColorStyle = style.filter(value => value.startsWith('fg:rgba')).first()
  if (fgColorStyle) { output.color = fgColorStyle.substring(3) }

  const bgColor = style.filter(value => value.startsWith('bg:rgba')).first()
  if (bgColor) { output.background = bgColor.substring(3) }

  return output
}

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      )
    },
    callback
  )
}

export const Link = (baseUrl) => ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()
  if(url[0] === '/') {
    return <RouterLink to={url}>{children}</RouterLink>
  }
  return (
    <a
      href={baseUrl && getTargetSuffixedUrl(url, baseUrl) || url}
      target={baseUrl && getTarget(url, baseUrl) || undefined}
    >
      {children}
    </a>
  )
}

export function getLinkCompositeDecorator(baseUrl) {
  const decorator = new CompositeDecorator([{ strategy: findLinkEntities, component: Link(baseUrl) }])
  return decorator
}

export function getWeightFromFontVariant(variant) {
  const match = variant.match(/(\d{3})|(regular)|(bold)/g)
  if(match !== null && match[0] !== 'regular') {
    return match[0]
  } else {
    return 'normal'
  }
}

export function getStyleFromFontVariant(variant) {
  const match = variant.match(/(italic)/g)
  if(match !== null) {
    return match[0]
  } else {
    return 'normal'
  }
}
