import ItemsGrouper from './itemsGrouper'
import namedProps from './namedProps'
import {
  getCompensatedYPos,
  getAspectRatioSize,
  relativeDimension,
  getNextAvailableIndex,
  isSelectedWriting,
  colorToCss,
  cssToColor,
  roundTo,
  extractUrlBasename,
} from './common'
import redirectPage from './redirectPage'
import {
  replicateAlways,
  replicateUntilChanged
} from './replicateToChildren'
import {
  getRawContentFromText,
  filterRawContentKey,
  getRawContentFromState,
  removeSelectionStyle,
  getStyleFgColor,
  getStyleBgColor,
  editorStateFromContent,
  getTextStyleFromSelection,
  getSelectionLink,
  getEditorSelectionCssStyle,
  getLinkCompositeDecorator,
  getWeightFromFontVariant,
  getStyleFromFontVariant,
} from './text'
import { moveSelectionToStart } from './textHelpers'
import { EditorStyler } from './editorStyler'
import {uploadImage} from './image'
import jsonFromResponseOk from './jsonFromResponseOk'
import jsonFromResponseOkOrReason from './jsonFromResponseOkOrReason'
import assertResponseOk from './assertResponseOk'
import { getYoutubeVideoId } from './youtube'
import { getTarget, getTargetSuffixedUrl } from './linkTarget'
import MockedFormData from './mockedFormData'

export {
  ItemsGrouper,
  namedProps,
  getCompensatedYPos,
  getAspectRatioSize,
  relativeDimension,
  getNextAvailableIndex,
  getRawContentFromText,
  filterRawContentKey,
  getRawContentFromState,
  removeSelectionStyle,
  getStyleFgColor,
  getStyleBgColor,
  editorStateFromContent,
  getTextStyleFromSelection,
  getSelectionLink,
  EditorStyler,
  getEditorSelectionCssStyle,
  getLinkCompositeDecorator,
  getWeightFromFontVariant,
  getStyleFromFontVariant,
  isSelectedWriting,
  moveSelectionToStart,
  jsonFromResponseOk,
  jsonFromResponseOkOrReason,
  assertResponseOk,
  colorToCss,
  cssToColor,
  redirectPage,
  roundTo,
  extractUrlBasename,
  replicateAlways,
  replicateUntilChanged,
  uploadImage,
  getYoutubeVideoId,
  getTarget,
  getTargetSuffixedUrl,
  MockedFormData,
}

export default {
  ItemsGrouper,
  namedProps,
  getCompensatedYPos,
  getAspectRatioSize,
  relativeDimension,
  getNextAvailableIndex,
  getRawContentFromText,
  filterRawContentKey,
  getRawContentFromState,
  removeSelectionStyle,
  getStyleFgColor,
  getStyleBgColor,
  editorStateFromContent,
  getTextStyleFromSelection,
  getSelectionLink,
  EditorStyler,
  getEditorSelectionCssStyle,
  getLinkCompositeDecorator,
  getWeightFromFontVariant,
  getStyleFromFontVariant,
  isSelectedWriting,
  moveSelectionToStart,
  jsonFromResponseOk,
  jsonFromResponseOkOrReason,
  assertResponseOk,
  colorToCss,
  cssToColor,
  redirectPage,
  roundTo,
  extractUrlBasename,
  replicateAlways,
  replicateUntilChanged,
  uploadImage,
  getYoutubeVideoId,
  getTarget,
  MockedFormData,
}
