import types from '../actionTypes'
import cookies from 'react-cookie'
import { jsonFromResponseOkOrReason } from '../../utils'
import { fetchArticleSideEffect } from './app'

export const openPDFImportModal = () => ({ type: types.PDF_IMPORT_OPENED })

export const closePDFImportModal = () => ({ type: types.PDF_IMPORT_CLOSED })

export const importPDFFile = (file) => (dispatch, getState) => {
  const {
    main: { currentArticle },
    pages: { active: activePage },
  } = getState()
  const formData = new FormData()
  formData.append('page', activePage)
  formData.append('file', file)
  return fetch(`/api/v1/articles/${currentArticle}/import-pdf/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': cookies.load('csrftoken'),
    },
    body: formData
  })
    .then(jsonFromResponseOkOrReason)
    .then(json => fetchArticleSideEffect(dispatch, json))
    .then(() => ({ success: true }))
    .catch(reasonPromise => reasonPromise.then(reason => ({ success: false, reason })))
}
