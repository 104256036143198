export default class ItemsGrouper {
  constructor(flatItems, screenSize) {
    this.flatItems = flatItems.sort((a, b) => a.pos[1] - b.pos[1])
    this.screenSize = screenSize
    this.groups = []
  }

  group = () => {
    const correctionFactor = this.screenSize / window.innerWidth

    this.flatItems.forEach(item => {
      const pos = [
        item.pos[0] * correctionFactor,
        item.pos[1] * correctionFactor
      ]

      const end = [
        item.end[0] * correctionFactor,
        item.end[1] * correctionFactor
      ]

      const size = [
        item.size[0] * correctionFactor,
        item.size[1] * correctionFactor
      ]

      const updatedItem = Object.assign({}, item, {pos, end, size})

      const interceptedGroup = this.getInterception(updatedItem)

      if(interceptedGroup === null) {
        this.startNewGroup(updatedItem)
      } else {
        this.attachToGroup(updatedItem, interceptedGroup)
      }
    })
    this.compensatePositions()
    return this.groups
  }

  getInterception = (item) => {
    for(var index=0; index<this.groups.length; index++) {
      const group = this.groups[index]
      const doesItemInterceptGroup = (
        (item.pos[1].between(group.pos[1], group.end[1])) ||
        (item.end[1].between(group.pos[1], group.end[1]))
      )
      const doesItemContainGroup = (
        (item.pos[1] < group.pos[1]) &&
        (item.end[1] > group.end[1])
      )
      if (doesItemInterceptGroup || doesItemContainGroup) {
        return index
      }
    }
    return null
  }

  startNewGroup = (item) => {
    this.groups.push({
      pos: item.pos,
      end: item.end,
      size: item.size,
      items: [{pos: item.pos, item}]
    })
  }

  attachToGroup = (item, groupIndex) => {
    let group = this.groups[groupIndex]
    group.pos = [
      Math.min(group.pos[0], item.pos[0]),
      Math.min(group.pos[1], item.pos[1])
    ]
    group.end = [
      Math.max(group.end[0], item.end[0]),
      Math.max(group.end[1], item.end[1])
    ]
    group.size = [
      group.end[0] - group.pos[0],
      group.end[1] - group.pos[1]
    ]
    group.items.push({pos: item.pos, item})
  }

  /*
   * Recalculate items position discounting the group position.
   */
  compensatePositions = () => {
    this.groups.forEach((group, i) => {
      group.items.forEach((item, j) => {
        this.groups[i].items[j] = Object.assign(item, {pos: [
          item.pos[0] - group.pos[0],
          item.pos[1] - group.pos[1]
        ]})
      })
    })
  }
}
