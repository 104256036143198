import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const connectorFactory = (actionCreators, context = undefined) => (connectables) => (ComponentToWrap) => {
  let mapDispatchToProps = undefined
  if (connectables.indexOf('actions') != -1) {
    connectables.splice(connectables.indexOf('actions'), 1)
    mapDispatchToProps = dispatch => ({
      actions: bindActionCreators(actionCreators, dispatch)
    })
  }
  const mapStateToProps = (state) => (
    connectables.reduce((result, key) => (result[key] = state[key], result), {})
  )
  let options = undefined
  if (context !== undefined) {
    options = { context }
  }
  return connect(mapStateToProps, mapDispatchToProps, undefined, options)(ComponentToWrap)
}

export default connectorFactory
