import types from '../actionTypes'
import { OrderedMap } from 'immutable'


export const initialProperties = {
  timeout: {
    left: null,
    top: null,
    width: null,
    height: null
  },
  appliedTextBGColor: {r: 255, g: 255, b: 255, a: 0},
  appliedTextFGColor: {r: 0, g: 0, b: 0, a: 1},
  appliedTextBold: false,
  appliedTextItalic: false,
  appliedTextUnderline: false,
  appliedTextStrikeThrough: false,
  appliedTextLink: '',
  textStyleHolder: false,

  imageUploadProgress: null,
  shapeInitialColor: {r: 255, g: 102, b: 102, a: 1},
  shapeInitialBorderSize: "0",
  shapeInitialBorderColor: {r: 0, g: 0, b: 0, a: 1},
  saveItemsTimeout: null,
  hasDirtyItems: false,
  selectedImage: null,
  pickedScreenSize: null,
  readDrawingAreaSize: null,
  appliedDrawingAreaWidth: null,
  textInitialFontFace: 'Open Sans',
  textInitialFontVariant: 'regular',
  textInitialFontSize: 1.6,
  previewFontsLoaded: false,
  activeTab: 'selected',
  article_slug_validations: OrderedMap(),
}

/**
 * Update the given dimension timeout value and reset the old one if needed.
 */
function resetDimensionTimeout(state, action) {
  if(state.timeout[action.dimension]) {
    clearTimeout(state.timeout[action.dimension])
  }
  const newState = {[action.dimension]: action.timeoutId}
  return Object.assign({}, state, {
    timeout: Object.assign({}, state.timeout, newState)
  })
}


function resetSaveItemTimeout(state, action) {
  if (state.saveItemsTimeout) {
    clearTimeout(state.saveItemsTimeout)
  }
  return Object.assign({}, state, {saveItemsTimeout: action.timeout})
}


export default function properties(state=initialProperties, action) {
  switch (action.type) {
    case types.RESET_TIMEOUT:
      return resetDimensionTimeout(state, action)

    case types.SET_TEXT_ITEM_FG_COLOR:
      return Object.assign({}, state, {appliedTextFGColor: action.color})

    case types.SET_TEXT_ITEM_BG_COLOR:
      return Object.assign({}, state, {appliedTextBGColor: action.color})

    case types.SET_TEXT_ITEM_BOLD:
      return Object.assign({}, state, {appliedTextBold: !state.appliedTextBold})

    case types.SET_TEXT_ITEM_ITALIC:
      return Object.assign({}, state, {appliedTextItalic: !state.appliedTextItalic})

    case types.SET_TEXT_ITEM_UNDERLINE:
      return Object.assign({}, state, {appliedTextUnderline: !state.appliedTextUnderline})

    case types.SET_TEXT_ITEM_STRIKETHROUGH:
      return Object.assign({}, state, {appliedTextStrikeThrough: !state.appliedTextStrikeThrough})

    case types.SET_IMAGE_UPLOAD_PROGRESS:
      return Object.assign({}, state, {imageUploadProgress: action.progress})

    case types.RESET_IMAGE_UPLOAD_PROGRESS:
      return Object.assign({}, state, {imageUploadProgress: null})

    case types.STORE_CURRENT_TEXT_STYLE:
      return {
        ...state,
        appliedTextFGColor: action.payload.style.color,
        appliedTextBGColor: action.payload.style.bgColor,
        appliedTextBold: action.payload.style.isBold,
        appliedTextItalic: action.payload.style.isItalic,
        appliedTextUnderline: action.payload.style.isUnderline,
        appliedTextStrikeThrough: action.payload.style.isStrikeThrough,
        appliedTextLink: action.payload.link,
        textStyleHolder: !state.textStyleHolder,
      }

    case types.SET_TEXT_ITEM_URL:
      return Object.assign({}, state, {appliedTextLink: action.url})

    case types.SET_SHAPE_DEFAULT_COLOR:
      return Object.assign({}, state, {shapeInitialColor: action.color})

    case types.SET_SHAPE_DEFAULT_BORDER_SIZE:
      return Object.assign({}, state, {shapeInitialBorderSize: action.size})

    case types.SET_SHAPE_DEFAULT_BORDER_COLOR:
      return Object.assign({}, state, {shapeInitialBorderColor: action.color})

    case types.SET_TEXT_ITEM_DEFAULT_FONT_FACE:
      return Object.assign({}, state, {textInitialFontFace: action.fontFace, textInitialFontVariant: action.fontVariant})

    case types.SET_TEXT_ITEM_DEFAULT_FONT_SIZE:
      return Object.assign({}, state, {textInitialFontSize: action.fontSize})

    case types.UPDATE_ITEM_SAVE_TIMER:
      return resetSaveItemTimeout(state, action)

    case types.SET_SCREEN_SIZE: {
      let appliedDrawingAreaWidth

      if (!action.size || state.readDrawingAreaSize[0] < action.size[0]) {
        appliedDrawingAreaWidth = state.readDrawingAreaSize[0]
      } else {
        appliedDrawingAreaWidth = action.size[0]
      }

      return Object.assign({}, state, {pickedScreenSize: action.size, appliedDrawingAreaWidth})
    }

    case types.SET_ITEM_DIRTY:
      return Object.assign({}, state, {hasDirtyItems: true})

    case types.SET_ITEM_TO_DELETE:
      return Object.assign({}, state, {hasDirtyItems: true})

    case types.SAVE: {
      const clearedTimeout = resetSaveItemTimeout(state, {timeout: null})
      return Object.assign({}, clearedTimeout, {hasDirtyItems: false})
    }

    case types.SET_IMAGE:
      return Object.assign({}, state, {selectedImage: action.image})

    case types.UNPICK_TOOL:
      return Object.assign({}, state, {selectedImage: null})

    case types.STORE_DRAWING_AREA_SIZE: {
      let appliedDrawingAreaWidth = state.appliedDrawingAreaWidth

      if (!state.pickedScreenSize) {
        appliedDrawingAreaWidth = action.size[0]
      }

      return Object.assign({}, state, {readDrawingAreaSize: action.size, appliedDrawingAreaWidth})
    }

    case types.SET_PREVIEW_FONTS_LOADED:
      return Object.assign({}, state, {previewFontsLoaded: true})

    case types.SET_CURRENT_TAB:
      return Object.assign({}, state, {activeTab: action.tab})

    case types.ARTICLE_SLUG_VALIDATED:
      return Object.assign({}, state, {
        article_slug_validations: state.article_slug_validations.set(action.payload.slug, action.payload.is_used)
      })

    default:
      return state
  }
}
