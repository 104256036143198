import React from 'react'
import { withRouter } from 'react-router'

import connector from "player/connector"
import withPiwikRouter from '../../helpers/piwikReactRouter'
import { appName, appVersion } from '../../settings'

@connector(['publication'])
@withRouter
@withPiwikRouter
export default class UnloadArticleButton extends React.Component {
  handleClick = (event) => {
    event.preventDefault()
    const {
      publication: { piwik_dimensions },
      history,
      piwikRouter,
      path,
    } = this.props
    if (piwikRouter) {
      piwikRouter.push(['setCustomDimension', piwik_dimensions.appName, appName])
      piwikRouter.push(['setCustomDimension', piwik_dimensions.appVersion, appVersion])
      piwikRouter.track({ pathname: path })
    }
    history.push(path)
  }

  render() {
    const { path } = this.props
    return (
      <a
        href={path}
        className="header-button navigation back-to-grid"
        onClick={this.handleClick}
      >
        Back
      </a>
    )
  }
}
