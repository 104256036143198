import React from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import LoadingLayer from 'common/components/LoadingLayer'
import connector from 'player/connector'
import MainHead from './MainHead'
import Header from './Header'
import Articles from './Articles'
import Article from './Article'

@connector(['loading', 'publication', 'actions'])
export default class Reader extends React.Component {
  componentDidMount() {
    document.body.addEventListener('keydown', this.handleScroll)
    const { actions, publicationId } = this.props
    actions.loadPublication(publicationId)
    actions.loadContents(publicationId)
    actions.loadCurrentUser()
  }

  handleScroll = (event) => {
    const container = document.getElementById('preview-container')
    if(event.key == 'ArrowUp') {
      container.scrollBy(0, -20)
      return
    }

    if(event.key == 'ArrowDown') {
      container.scrollBy(0, 20)
      return
    }

    if(event.key == 'PageUp') {
      container.scrollBy(0, -200)
      return
    }

    if(event.key == 'PageDown') {
      container.scrollBy(0, 200)
      return
    }

    let button
    if(event.key == 'ArrowLeft') {
      button = document.getElementById('backward-button')
    } else if(event.key == 'ArrowRight') {
      button = document.getElementById('forward-button')
    }
    if(button) {
      button.click()
    }
  }

  render() {
    const {
      loading,
      publication: { initial_page_slug },
    } = this.props

    if(initial_page_slug === null) {
      return <LoadingLayer loading={loading} />
    }

    return (
      <BrowserRouter ref="router">
        <div>
          <MainHead />

          <Header />

          <Switch>
            <Route path="/" exact>
              {initial_page_slug
                ? <Redirect to={`/${initial_page_slug}`} />
                : <Articles />
              }
            </Route>

            <Route path="/blog" exact>
              {initial_page_slug
                ? <Articles />
                : <Redirect to="/" />
              }
            </Route>

            <Route path="/:slug/:page?">
              <Article />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}
