import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Route } from 'react-router-dom'
import connector from "player/connector"
import UnloadArticle from './UnloadArticle'
import BackwardButton from './BackwardButton'
import ForwardButton from './ForwardButton'
import SubscriberButton from './SubscriberButton'
import PageProgressBar from './PageProgressBar'

const Header = ({
  publication: { name, logo, initial_page_slug },
  options: { isStandalone },
  loading,
  history: { location: { pathname } },
}) => {
  const gridUrl = initial_page_slug ? '/blog' : '/'
  if(loading || (isStandalone && pathname !== gridUrl)) {
    return null
  }

  return (
    <header className="player-header">
      <h1 className="center">
        {
          logo
            ? <img src={logo} className="logo"/>
            : <span className="publication-name">{name}</span>
        }
      </h1>

      <div className="left">
        <UnloadArticle />
      </div>

      <div className="right">
        {pathname != gridUrl && <Route path="/:slug/:page?" component={BackwardButton} />}
        {pathname != gridUrl && <Route path="/:slug/:page?" component={ForwardButton} />}
        {pathname == gridUrl && <SubscriberButton />}
      </div>

      <div className="bottom">
        {pathname != gridUrl && <PageProgressBar />}
      </div>
    </header>
  )
}

Header.propTypes = {
  publication: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    initial_page_slug: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default withRouter(connector(['publication', 'options', 'loading'])(Header))
