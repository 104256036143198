/*
 * Mocked FormData class to be used in snapshot testing.
 *
 * The default FormData class doesn't render itself in snapshots, so we use an
 * alternative version that is an array with an append method instead of a push
 * method.
 */
class MockedFormData extends Array {
  append = (name, value) => this.push({ name, value })
}

export default MockedFormData
