/**
 * Add a *between* method for numbers.
 */
Number.prototype.between = function(a, b) {
  return this >= a && this <= b
}

/*
 * Get the header height and the compensated y position.
 */
export function getCompensatedYPos(pos) {
  const headerHeight = document.getElementById('header').offsetHeight
  return pos - headerHeight
}

/*
 * Return the "type" measure with aspect ratio from given data
 */
export function getAspectRatioSize(type, width, height, newValue) {
  let value
  if (type == 'width') {
    value = (width / height) * newValue
  } else if (type == 'height') {
    value =  (height / width) * newValue
  }
  return Math.floor(value)
}

/*
 * Convert the value to a percentage of the base, rounded up to 3 decimal places.
 */
export function relativeDimension(value, base) {
  const percentualValue = value / base * 100
  return Math.round(percentualValue * 1E3) / 1E3
}


/*
 * Returns the next available integer index from a Immutable's map object.
 */
export function getNextAvailableIndex(map) {
  const index = map.keySeq().max()
  if(index === undefined) {
    return 1
  } else {
    return index + 1
  }
}


/*
 * Tells if the selected item from state is in writing mode.
 */
export function isSelectedWriting(itemsState) {
  return itemsState.some(value => value.selected && value.isWriting)
}

/*
 * Convert a color mapping of r, g, b and a into a css compatible string.
 */
export function colorToCss(colorMap) {
  const {r, g, b, a} = colorMap
  return `rgba(${r}, ${g}, ${b}, ${a})`
}

export function cssToColor(cssColor) {
  const colorArray = cssColor
    .replace('rgba(', '')
    .replace(')', '')
    .split(', ')

  return {
    r: parseInt(colorArray[0]),
    g: parseInt(colorArray[1]),
    b: parseInt(colorArray[2]),
    a: parseFloat(colorArray[3])
  }
}

export function roundTo(number, decimalPlaces) {
  return Math.round(number * 10 * decimalPlaces) / (10 * decimalPlaces)
}

export function extractUrlBasename(url) {
  if (url) {
    return url.split('/').pop().split('?').shift()
  }

  return ''
}
