import React from 'react'
import PropTypes from 'prop-types'
import {
  Editor,
  EditorState,
  convertFromRaw,
} from 'draft-js'
import {
  getEditorSelectionCssStyle,
  getLinkCompositeDecorator,
  getWeightFromFontVariant,
  getStyleFromFontVariant,
} from '../../../utils'

export default class PreviewText extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      editorState: this.getInitialEditorState(),
      autoSaveTimeout: null  // TODO migrate to Text
    }
  }

  getInitialEditorState = () => {
    const { options: { publication: { marketing_url } } } = this.props
    if(this.props.item.content) {
      const content = convertFromRaw(this.props.item.content)
      return EditorState.createWithContent(content, getLinkCompositeDecorator(marketing_url))
    } else {
      return EditorState.createEmpty()
    }
  }

  getClassNames = () => [
    'fill-parent',
    this.props.item.alignment ? 'text-' + this.props.item.alignment : '',
  ]

  getContainerStyle = () => {
    const styles = {
      fontFamily: this.props.item.textFontFace,
      fontSize: this.props.item.textFontSize + 'em',
      fontWeight: getWeightFromFontVariant(this.props.item.textFontVariant),
      fontStyle: getStyleFromFontVariant(this.props.item.textFontVariant),
    }

    if (this.props.item.textAdaptability == 'SCALE_TO_FIT') {
      const scale = this.getTextScale()
      Object.assign(styles, {
        width: 100 / scale + '%',
        transform: "scale(" + scale + ")",
        transformOrigin: "left top",
      })
    }
    return styles
  }

  getTextScale = () => this.props.properties.appliedDrawingAreaWidth / this.props.item.textFontSizeOnWidth

  render() {
    return (
      <div
        className={this.getClassNames().join(' ')}
        style={this.getContainerStyle()} >
        <div>
          <Editor
            ref="editor"
            editorState={this.state.editorState}
            customStyleFn={getEditorSelectionCssStyle}
            readOnly
          />
        </div>
      </div>
    )
  }
}
