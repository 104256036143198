import 'isomorphic-fetch'
import cookies from 'react-cookie'

import types from '../actionTypes'
import settings from '../../settings'


export const uploadCoverImage = (image, articleId) => (dispatch) => {
  const body = new FormData()
  body.append('image', image)

  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('post', settings.articleCoverUrl(articleId))
    xhr.setRequestHeader('X-CSRFToken', cookies.load('csrftoken'))
    xhr.onload = e => resolve(e.target.responseText)
    xhr.onerror = reject

    xhr.send(body)
  }).then(data => {
    dispatch({type: types.DEFINE_ARTICLE_COVER, ...JSON.parse(data)})
  }).catch(err => {
    throw(err)
  })
}

export const loadArticlesFromPublication = publicationId => (dispatch) => {
  return fetch(`${settings.publicationsArticlesListUrl(publicationId)}?page_size=20`, {credentials: 'same-origin'})
    .then(response => response.json())
    .then(json => {
      dispatch({type: types.SET_CURRENT_PUBLICATION_ARTICLES, articles: json.results})
      dispatch({type: types.STORE_ARTICLES_NEXT_PAGE, nextPage: json.next})
    })
    // eslint-disable-next-line no-console
    .catch(e => console.log('Error loading articles:', e.message))
}

export const loadArticlesNextPage = () => (dispatch, getState) => {
  const state = getState()
  if (state.properties.articlesNextPage) {
    return fetch(state.properties.articlesNextPage, {credentials: 'same-origin'})
      .then(response => response.json())
      .then(json => {
        dispatch({type: types.APPEND_CURRENT_PUBLICATION_ARTICLES, articles: json.results})
        dispatch({type: types.STORE_ARTICLES_NEXT_PAGE, nextPage: json.next})
      })
      // eslint-disable-next-line no-console
      .catch(e => console.log('Error loading next page articles:', e.message))
  }
}
