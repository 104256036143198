import types from '../actionTypes'
import {OrderedMap} from 'immutable'

export const initialState = {
  fontDownloadStatus: 'NOT_STARTED',
  fontList: OrderedMap(),
  imageList: OrderedMap(),
  currentArticle: null,
  isCurrentArticlePreviewOpen: false,
  isPDFImportOpen: false,
}

export default function main(state = initialState, action) {
  switch (action.type) {
    case types.ONGOING_FONT_DOWNLOAD:
      return Object.assign({}, state, {fontDownloadStatus: 'ONGOING'})

    case types.DONE_FONT_DOWNLOAD:
      return Object.assign({}, state, {fontDownloadStatus: 'DONE', fontList: action.payload})

    case types.FAILED_FONT_DOWNLOAD:
      return Object.assign({}, state, {fontDownloadStatus: 'FAILED'})

    case types.ADD_IMAGE_FILE:
      return Object.assign({}, state,
        {imageList: state.imageList.set(action.data.id, action.data)}
      )

    case types.UPDATE_IMAGES_LIST:
      return Object.assign({}, state, {imageList: action.images})

    case types.SET_CURRENT_ARTICLE:
      return Object.assign({}, state, {currentArticle: action.articleId})

    case types.UNLOAD_ARTICLE:
      return Object.assign({}, state, {currentArticle: null})

    case types.OPEN_CURRENT_ARTICLE_PREVIEW_DONE:
      return {...state, isCurrentArticlePreviewOpen: true}

    case types.CLOSE_CURRENT_ARTICLE_PREVIEW_DONE:
      return {...state, isCurrentArticlePreviewOpen: false}

    case types.PDF_IMPORT_OPENED:
      return {...state, isPDFImportOpen: true}

    case types.PDF_IMPORT_CLOSED:
      return {...state, isPDFImportOpen: false}

    default:
      return state
  }
}
