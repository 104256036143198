export const getTarget = (link, domain) => {
  const fixedLink = link ? link.replace('https', 'http') : ''
  const fixedDomain = domain.replace(/\/$/, '').replace('https', 'http')
  return fixedLink.startsWith(fixedDomain) ? '_self' : '_blank'
}

export const getTargetSuffixedUrl = (link, domain) => {
  const target = getTarget(link, domain)
  const separator = link.indexOf('?') === -1 ? '?' : '&'
  return `${link}${separator}mt_target=${target}`
}
