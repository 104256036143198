import types from '../actionTypes'

export const defaultState = {
  id: null,
  logo: '',
  initial_page_slug: null,
}


export default (state=defaultState, action) => {
  switch(action.type) {
    case types.LOAD_PUBLICATION_DONE:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
