import React from 'react'
import {withRouter} from 'react-router'
import connector from "player/connector"

@withRouter
@connector(['pages', 'options'])
export default class ForwardButton extends React.Component {
  goForward = (event) => {
    event.preventDefault()
    this.props.history.push(this.path)
  }

  getLabel() {
    const { pages: { next } } = this.props
    return next ? 'Page' : 'Article'
  }

  render() {
    const {
      pages: { next },
      options: { previousArticle },
      match: { params: { slug } },
    } = this.props

    let path = ''
    if(next) {
      const nextPageIndex = this.props.pages.pages.keySeq().indexOf(next)
      path = `/${slug}/page-${nextPageIndex + 1}`
    } else {
      path = `/${previousArticle}`
    }
    this.path = path

    if(previousArticle || next) {
      return (
        <a
          id="forward-button"
          href={path}
          className="header-button navigation next"
          onClick={this.goForward}
        >
          Next <span>{this.getLabel()}</span>
        </a>
      )
    } else {
      return (
        <span
          className="header-button navigation next disabled"
        >
          Next <span>{this.getLabel()}</span>
        </span>
      )
    }
  }
}
