import types from "../actionTypes"


export const initialPlacement = {
  selectedTool: null
}


export default function placement(state=initialPlacement, action) {
  switch (action.type) {
    case types.PICK_TOOL:
      return Object.assign({}, state, {
        selectedTool: action.tool
      })

    case types.UNPICK_TOOL:
      return Object.assign({}, state, {
        selectedTool: null
      })

    default:
      return state
  }
}
