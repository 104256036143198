import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getTarget, getTargetSuffixedUrl } from '../../../utils'
import PreviewText from './PreviewText'
import Rectangle from './Rectangle'
import Image from './Image'
import Ellipse from './Ellipse'
import { YoutubeEmbed, GenericEmbed } from './Embed'

export const ItemWrapper = ({ headingLevel, children, ...props }) => (
  React.createElement(headingLevel || 'div', props, children)
)

export default class PreviewItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  }

  constructor() {
    super()
    this.TextComponent = PreviewText
    this.previewMode = true  // some internal components rely in this flag to represent ifself different
  }

  buildComponent() {
    const TextComponent = this.TextComponent
    const {type} = this.props.item
    const opacity = (this.props.item.isResizing || this.props.item.isDragging) ? 0 : 1

    const props = Object.assign({}, {ref: 'innerItem', previewMode: this.previewMode}, this.props)
    return (
      <div id={"item-" + this.props.item.id} style={{opacity}} className="fill-parent">
        {type == 'rectangle' && <Rectangle {...props}/>}
        {type == 'text' && <TextComponent {...props}/>}
        {type == 'image' && <Image {...props}/>}
        {type == 'ellipse' && <Ellipse {...props}/>}
        {type == 'youtube' && <YoutubeEmbed {...props}/>}
        {type == 'embed' && <GenericEmbed {...props}/>}
      </div>
    )
  }

  getCommonClassNames = () => [
    'drawing-item',
    this.props.item.type,
    this.props.item.isFixed ? 'fixed' : '',
  ]

  getCustomClassNames = () => []

  getClassNames = () => [
    ...this.getCommonClassNames(),
    ...this.getCustomClassNames()
  ]

  getItemStyle = () => {
    const correctionFactor = this.props.properties.appliedDrawingAreaWidth / window.innerWidth
    return ({
      left: this.props.item.pos[0] * correctionFactor + 'vw',
      top: this.props.item.pos[1] * correctionFactor + 'vw',
      width: this.props.item.size[0] * correctionFactor + 'vw',
      height: this.props.item.size[1] * correctionFactor + 'vw',
      zIndex: -1 * (this.props.item.itemOrder || 0)
    })
  }

  render() {
    const {
      item: { headingLevel, link },
      options: { publication: { marketing_url } },
    } = this.props
    const target = getTarget(link, marketing_url)
    let props = {
      className: this.getClassNames().join(' '),
      style: this.getItemStyle()
    }
    if(!link) {
      return <ItemWrapper headingLevel={headingLevel} {...props}>{this.buildComponent()}</ItemWrapper>
    } else if(link[0] === '/') {
      return <ItemWrapper headingLevel={headingLevel} {...props}><Link to={link}>{this.buildComponent()}</Link></ItemWrapper>
    } else {
      return <ItemWrapper headingLevel={headingLevel} {...props}><a target={target} href={getTargetSuffixedUrl(link, marketing_url)}>{this.buildComponent()}</a></ItemWrapper>
    }
  }
}
