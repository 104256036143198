import React from 'react'
import { withRouter } from 'react-router-dom'
import PreviewArea from 'layouter/components/preview/PreviewArea'
import withPiwikRouter from 'helpers/piwikReactRouter'
import {appName, appVersion} from 'settings'
import connector from "player/connector"
import ArticleHead from './ArticleHead'

@connector([
  'publication', 'main', 'items', 'properties', 'placement',
  'options', 'pages', 'actions', 'loading',
])
@withRouter
@withPiwikRouter
export default class Article extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.slug != nextProps.options.slug &&
      this.props.options.slug == nextProps.options.slug &&
      nextProps.loading == 0
    ) {
      this.props.actions.fetchArticleFromSlug(nextProps.publication.id, nextProps.match.params.slug)
    }

    if(nextProps.pages.pages.size) {
      const page = nextProps.match.params.page || 'page-1'
      let pageId
      if(page == 'last') {
        pageId = nextProps.pages.pages.last().id
      } else {
        const pageIndex = parseInt(page.replace('page-', '')) - 1
        pageId = nextProps.pages.pages.toList().get(pageIndex).id
      }
      if(pageId != nextProps.pages.active) {
        this.props.actions.setActivePage(pageId)
      }

      if (nextProps.loading == 0 && this.props.piwikRouter) {
        const articleId = nextProps.options.id
        const piwikDimensions = this.props.publication.piwik_dimensions
        this.props.piwikRouter.push(['setCustomDimension', piwikDimensions.appName, appName])
        this.props.piwikRouter.push(['setCustomDimension', piwikDimensions.appVersion, appVersion])
        this.props.piwikRouter.track({pathname: `/articles/${articleId}/page/${pageId}`})
      }
    }
  }

  render() {
    const { options: { isStandalone } } = this.props
    return (
      <div
        className={`wrapper player-article-detail ${isStandalone ? 'hide-decoration' : ''}`}
        tabIndex="1"
      >
        <ArticleHead {...this.props} />
        <PreviewArea {...this.props} />
      </div>
    )
  }
}
