import types from '../actionTypes'

/**
 * Pick (or unpick if already selected) the given tool.
 */
export const pickTool = (toolName) => (dispatch, getState) => {
  const state = getState()
  if((state.placement.selectedTool === toolName) || (toolName == 'arrow')) {
    dispatch({type: types.UNPICK_TOOL})
  } else {
    dispatch({type: types.PICK_TOOL, tool: toolName})
  }
}
