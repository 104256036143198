import React from 'react'
import LightBox from './LightBox'

const LoadingLayer = ({ loading }) => {
  if (loading > 0) {
    return (<LightBox />)
  }

  return null
}

export default LoadingLayer
