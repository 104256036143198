export default {
  LOAD_GROUPS_STARTED: 'LOAD_GROUPS_STARTED',
  LOAD_GROUPS_DONE: 'LOAD_GROUPS_DONE',
  LOAD_GROUP_STARTED: 'LOAD_GROUP_STARTED',
  LOAD_GROUP_DONE: 'LOAD_GROUP_DONE',
  SET_GROUPS: 'SET_GROUPS',
  APPEND_GROUP: 'APPEND_GROUP',
  LOAD_READERS_STARTED: 'LOAD_READERS_STARTED',
  LOAD_READERS_DONE: 'LOAD_READERS_DONE',
  SET_READERS: 'SET_READERS',
  APPEND_READERS: 'APPEND_READERS',
  APPEND_READER: 'APPEND_READER',
  SET_READER_EMAIL: 'SET_READER_EMAIL',
  SET_READER_FULL_NAME: 'SET_READER_FULL_NAME',
  READER_FIELD_CHANGED: 'READER_FIELD_CHANGED',
  SET_READER_START_DATE: 'SET_READER_START_DATE',
  SET_READER_END_DATE: 'SET_READER_END_DATE',
  SET_READER_USER: 'SET_READER_USER',
  SET_READER_DIRTY: 'SET_READER_DIRTY',
  RESET_READER_CREATION: 'RESET_READER_CREATION',
  GROUP_DELETE_STARTED: 'GROUP_DELETE_STARTED',
  GROUP_DELETED: 'GROUP_DELETED',
  GROUP_DELETE_FAILED: 'GROUP_DELETE_FAILED',
  GROUP_EDIT_STARTED: 'GROUP_EDIT_STARTED',
  GROUP_EDITED: 'GROUP_EDITED',
  GROUP_EDIT_FAILED: 'GROUP_EDIT_FAILED',
  READER_TOGGLE_SELECTION: 'READER_TOGGLE_SELECTION',
  READERS_TOGGLE_ALL_SELECTION: 'READERS_TOGGLE_ALL_SELECTION',
  READERS_DELETE_STARTED: 'READERS_DELETE_STARTED',
  READERS_DELETED: 'READERS_DELETED',
  READERS_DELETE_FAILED: 'READERS_DELETE_FAILED',
  READERS_MOVE_STARTED: 'READERS_MOVE_STARTED',
  READERS_MOVED: 'READERS_MOVED',
  READERS_MOVE_FAILED: 'READERS_MOVE_FAILED',
  READERS_CSV_IMPORT_STARTED: 'READERS_CSV_IMPORT_STARTED',
  READERS_CSV_IMPORT_DONE: 'READERS_CSV_IMPORT_DONE',
  READERS_CSV_IMPORT_FAILED: 'READERS_CSV_IMPORT_FAILED',
  READERS_SEARCH_UPDATED: 'READERS_SEARCH_UPDATED',
  READERS_NEXT_PAGE_CHANGED: 'READERS_NEXT_PAGE_CHANGED',
}
