import cookies from 'react-cookie'
import types from '../../actionTypes'
import settings from '../../../settings'


export const saveImage = (image) => (dispatch, getState) => {
  const state = getState()
  const body = new FormData()
  body.append('image', image)

  const onProgress = event => {
    if (event.lengthComputable) {
      const progress = Math.floor((event.loaded / event.total) * 100)
      dispatch({type: types.SET_IMAGE_UPLOAD_PROGRESS, progress})
    }
  }

  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', settings.articleSaveImageUrl(state.main.currentArticle))
    xhr.setRequestHeader('X-CSRFToken', cookies.load('csrftoken'))
    xhr.onload = e => resolve(e.target.responseText)
    xhr.onerror = reject

    if (xhr.upload)
      xhr.upload.onprogress = onProgress

    xhr.send(body)
  }).then(data => {
    dispatch({type: types.ADD_IMAGE_FILE, data: JSON.parse(data)})
    dispatch({type: types.RESET_IMAGE_UPLOAD_PROGRESS})
  }).catch(() => dispatch({type: types.RESET_IMAGE_UPLOAD_PROGRESS}))
}
