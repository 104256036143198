import 'isomorphic-fetch'
import cookies from 'react-cookie'
import moment from 'moment'
import types from '../../actionTypes'
import settings from '../../../settings'
import {jsonFromResponseOk} from '../../../utils'


export const save = () => (dispatch, getState) => {
  dispatch({type: types.SAVE_STARTED})
  const state = getState()
  const items = state.items.valueSeq().toArray()
    .filter(item => item.isDirty)
    .map(item => Object.assign({}, item))
    .map(item => {
      delete item.isDirty
      delete item.selected
      delete item.isResizing
      delete item.isDragging
      delete item.isWriting

      return {
        key: item.id,
        page: item.page,
        data: JSON.stringify(item),
      }
    })

  const pages = state.pages.pages.valueSeq().toArray()
    .map(page => ({
      id: page.id,
      title: page.title,
      index: page.index,
      is_removed: page.isRemoved,
      is_locked: page.isLocked,
      is_hidden: page.isHidden,
      name_index: page.nameIndex,
      background_color: page.backgroundColor,
    }))

  const options = {
    title: state.options.title,
    description: state.options.description,
    slug: state.options.slug,
    date: moment(state.options.date).format(),
    is_deleted: state.options.isDeleted,
    is_published: state.options.isPublished,
    is_restricted: state.options.isRestricted,
    is_standalone: state.options.isStandalone,
    background_color: state.options.projectBGColor,
    groups: state.options.groups,
  }

  return fetch(settings.articleUrl(state.main.currentArticle), {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': cookies.load('csrftoken'),
    }),
    body: JSON.stringify({
      ...options,
      pages,
      items,
    })
  })
    .then(jsonFromResponseOk)
    .then(json => {
      dispatch({type: types.SAVE, payload: json})
      dispatch({type: types.UNSET_OPTIONS_DIRTY})
      dispatch({type: types.SAVE_DONE})
    })
    .catch(error => {
      dispatch({type: types.SAVE_FAILED, payload: {error}})
    })
}
